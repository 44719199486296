import { getToken } from "api";
import axios from "axios";
import { ApiResponse } from "common/interfaces";
import { API_BASE_URL, API_RESSOURCES, GET_LIST_DATA } from "./teacher.routes";

export const findAll = async (): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(API_BASE_URL);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getDataList = async (
  type: string
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(GET_LIST_DATA(type), {
      headers: {},
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const uploadToCloudinary = async (
  image: any,
  type: any
): Promise<ApiResponse | undefined> => {

  const formData = new FormData();
  formData.append("file", image);

  try {
    const response = await axios.post(`${API_RESSOURCES}/users/profil/update/upload?type=${type}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }

};

export const checkCourseStatus = async (
  id: string
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get('', {
      headers: {},
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchCourseDocs = async (
  id: string
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get('', {
      headers: {},
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchSommary = async (
  id: string
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get('', {
      headers: {},
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getOrganismeList = async (): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL_ADMIN}/utilisateurs/organisme/instance/fetch/all`, {
      headers: {},
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getOrganismeStudents = async (organisationID: string): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL_ADMIN}/utilisateurs/organisme/instance/fetch/students/all`, {
      params: { organisationID },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateOrganismeCredit = async (
  id: string,
  credit: any,
): Promise<ApiResponse | undefined> => {
  try {
    const credits = parseFloat(credit);
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.post(`${process.env.REACT_APP_API_URL_ADMIN}/utilisateurs/organisme/update/credits`, { id, credits }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const setStudentCredit = async (
  organismeId: string,
  teacherId: string,
  studentId: string,
  credit: any,
): Promise<ApiResponse | undefined> => {
  try {
    const credits = parseFloat(credit);
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.post(`${process.env.REACT_APP_API_URL_ADMIN}/utilisateurs/organisme/student/credits/affect`, { organismeId, teacherId, studentId, credits }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const getStudentsAttibutions = async (teacherId: string, studentId: string): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL_ADMIN}/utilisateurs/organisme/instance/fetch/students/credits/all`, {
      params: { teacherId, studentId },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};