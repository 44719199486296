import { AutoComplete, Avatar, Badge, Button, Col, Dropdown, Form, Image, Input, Menu, Modal, Row, Spin, Steps, message, notification } from "antd";
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import * as USER_SERVICES from "api/services/chat/user-chat/user.services";
import { FC, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closePorteFeuilleDrawer__,
  closeProfileDrawer,
  openProfileDrawer,
} from "store/auth/authSlice";
import {
  closePaiementDrawer,
  openChatDrawerStudent,
  openPaiementDrawer,
} from "store/chat/mainSlice";

import * as appRoutes from "config/routes.config";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

//!COMPONENTS
import Container from "components/container";

//!IMAGES
import { DownOutlined } from '@ant-design/icons';
import call from "assets/images/app/header/call.svg";
import logo from "assets/images/app/header/logo.svg";
import TeacherIcon from "assets/images/teacher/teacher.svg";
import ActivitiesIcon from "./assets/images/ads.svg";

//TEACHER
import Dashboard from "./assets/images/dashboard.svg";

import MesClasses from "./assets/images/classes.svg";
import Content from "./assets/images/content.svg";
import MenuIcon from "./assets/images/menu.svg";
import Msg from "./assets/images/msg.svg";
import Prof from "./assets/images/prof.svg";

//!STYLES
import MenuModal from "screens/teacher/components/menu";
import s from "./assets/headers.module.css";
import style from "./assets/user.header.module.css";

//!REDUX
import { FLAGS, StatusCode, UserRoles } from "common/enums";
import AppButton from "components/libs/button";
import NotificationStudent from "components/notifications/student";
import UserNavBar from "components/userNavBar";
import { setIdReceiver, toggleAppChat } from "store/AppChat/appChat";
import { useFindRoomMutation } from "store/services/chat";
import {
  useGetNotifByUserQuery,
  useViewNotifByUserMutation,
} from "store/services/teacher/notification.services";
import NavUser from "./NavUser";

const { Step } = Steps;
const { Option } = AutoComplete;

const UserStudent: FC = () => {
  //!HOOKS
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { isFullscreen } = useSelector((state) => state.app);

  //!RETURN
  return (
    <div>
      {isLoggedIn && !isFullscreen && <WhenConnected />}
      {!isLoggedIn && <WhenNotConnected />}
    </div>
  );
};

const WhenConnected: FC = () => {
  const STUDENT = [
    {
      id: 1,
      name: "Accueil",
      icon: ActivitiesIcon,
      SizeIcon: 50,
      link: appRoutes.TEACHER_ACTIVITIES,
    },
    {
      id: 0,
      name: "Dashboard",
      icon: Dashboard,
      link: appRoutes.STUDENT_DASHBOARD,
    },
    {
      id: 2,
      name: "Mes classes",
      icon: MesClasses,
      iconSize: 30,
      link: appRoutes.STUDENT_MY_CLASS,
    },
    {
      id: 3,
      name: "Mon contenu",
      icon: Content,
      iconSize: 30,
      link: appRoutes.STUDENT_MY_CONTENT,
    },

    {
      id: 4,
      name: "Trouver un prof",
      icon: Prof,
      link: appRoutes.STUDENT_SEARCH_BEST_TEACHER,
    },
  ];

  //!HOOKS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const { currentChat } = useSelector((state) => state.appChat);
  const [formInfos] = Form.useForm();

  const {
    data: dataNotif,
    refetch,
    isLoading,
  } = useGetNotifByUserQuery(user._id);

  const [
    viewNotifByUser,
    { data: viewData, isLoading: isLoadingView, isSuccess: isSuccessView },
  ] = useViewNotifByUserMutation();

  //!STATE
  const [visible, setVisible] = useState(false);

  const openMessagerieDrawer = () => {
    dispatch(openChatDrawerStudent());
    dispatch(closeProfileDrawer());
    dispatch(closePorteFeuilleDrawer__());
    dispatch(closePaiementDrawer());
    setVisible(false);
  };
  const openPaiementStudentDrawer = () => {
    dispatch(openPaiementDrawer());
  };
  //!FUNCTIONS

  const openDrawer = () => dispatch(openProfileDrawer());

  let filterNotifForMsg =
    dataNotif?.data?.filter(
      (data: any) => data?.type === "messagerie" && data?.seen === false
    ) || [];

  const handleViewAllMessage = (arr: any[]) => {
    arr.map((msg: any) => {
      return viewNotifByUser(msg?._id);
    });
  };

  const [findRoom, { isSuccess }] = useFindRoomMutation();

  const handleDisplaySponsorMSG = () => {
    console.log(user?._id);
    console.log(user?.accountManaged);
    const finalData = {
      actor1: user?._id,
      actor2: user?.accountManaged,
      flag: FLAGS.SINGLE_FLAG,
      type: "",
      instaprof: "",
    };
    findRoom(finalData);
    dispatch(toggleAppChat(true));
    dispatch(setIdReceiver(user?.accountManaged));
  };

  const [visibleDemandModal, setVisibleDemandModal] = useState(false);
  const [visibleDissociationModal, setVisibleDissociationModal] = useState(false);
  const [isMessageLoading, setMessageLoading] = useState<boolean>(false);

  const [users, setUsers] = useState([]);
  const OnSelectUser = useCallback(async (userNameOrEmail: string) => {
    if (userNameOrEmail.length >= 3) {
      const roles = [UserRoles.TEACHER];

      try {
        const response = await USER_SERVICES.findOne(userNameOrEmail, roles);
        if (response?.status === "OK") {
          setUsers(response?.data);
        } else {
          if (userNameOrEmail.length >= 10) {
            message.warning(response?.message);
          }
        }
      } catch (error) {
        if (userNameOrEmail.length >= 6) {
          notification["info"]({
            message: "Information",
            description: "Utilisateur non trouvé : )",
            style: {
              width: 300,
            },
          });
        }
      }
    }
  }, []);

  const showDemandModal = () => {
    setVisibleDemandModal(true);
  };

  const handleCancel = () => {
    setVisibleDemandModal(false);
  };

  const showDissociationModal = () => {
    setVisibleDissociationModal(true);
  };

  const handleCancelDissociation = () => {
    setVisibleDissociationModal(false);
  };

  const [demandTeacherId, setDemandTeacherId] = useState<string>('');

  const dropdownMenu = (
    <Menu>
      <Menu.Item key="message" onClick={handleDisplaySponsorMSG}>Envoyer un message</Menu.Item>
      <Menu.Item key="budget" onClick={showDemandModal}>Demander un budget</Menu.Item>
      <Menu.Item key="params" onClick={showDissociationModal}>Paramètres avancés</Menu.Item>
    </Menu>
  );

  const sendDemand = async (values: any) => {
    setMessageLoading(true);
    try {
      const response = await AUTH_SERVICES.sendBudgetDemand(user?.accountManaged, demandTeacherId, user?._id, values.montant, values?.message);
      if (response?.statusCode === StatusCode.CREATED) {
        message.success({
          content: "Demande envoyée ",
        });
        setMessageLoading(false);
        setVisibleDemandModal(false);
      }
      else {
        message.warning({
          content: "Ooops, Une erreur est survenue",
        });
        setMessageLoading(false);
      }
    } catch (error: any) {
      let content = "";
      for (
        let index = 0;
        index < error?.response?.data?.message?.length;
        index++
      ) {
        content = error?.response?.data?.message[index] + ", " + content;
      }
      message.error({
        content: content,
      });
    }
  }

  const dissociateAccount = async () => {
    setMessageLoading(true);
    try {
      const response = await AUTH_SERVICES.dissociateAccount(user?._id);
      if (response?.statusCode === StatusCode.CREATED) {
        message.success({
          content: "Compte deconnecté ",
        });
        setMessageLoading(false);
        setVisibleDissociationModal(false);
      }
      else {
        message.warning({
          content: "Ooops, Une erreur est survenue",
        });
        setMessageLoading(false);
      }
    } catch (error: any) {
      let content = "";
      for (
        let index = 0;
        index < error?.response?.data?.message?.length;
        index++
      ) {
        content = error?.response?.data?.message[index] + ", " + content;
      }
      message.error({
        content: content,
      });
    }
  }

  //!RETURN
  return (
    <>
      {user?.organisationName !== "" && <div className={style.topBar} style={{ justifyContent: "flex-end" }}>
        <div>Compte géré par : {user?.organisationName}</div>
        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
          <Dropdown overlay={dropdownMenu} placement="bottomLeft">
            <Button>
              Menu <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>}
      <header className={style.header} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <div>
          <div className={style.menu}>
            <div className={style.logo}>
              <Link to={"/"} >
                <Image preview={false} src={logo} width={150} />
              </Link>
            </div>

            <div
              className={style.header__menu}
              onClick={() => setVisible(!visible)}
            >
              <img src={MenuIcon} alt="" width={26} />
            </div>

            {/* desktop-menu */}
            <nav className={style.nav__bar}>
              <ul className={style.nav__item}>
                {STUDENT.map((item) => (
                  <li className={style.__isMobile}>
                    <ul>
                      <li>
                        <Link to={item?.link}>
                          <Image
                            preview={false}
                            src={item.icon}
                            width={item?.iconSize || 29}
                          />
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to={item?.link}>{item.name}</Link>
                      </li>
                    </ul>
                  </li>
                ))}

                <li className={style.newNavBar}>
                  <UserNavBar />
                </li>

                <Badge size="small" count={filterNotifForMsg?.length}>
                  <li
                    onClick={() => {
                      dispatch(toggleAppChat(true));
                      handleViewAllMessage(filterNotifForMsg);
                    }}
                    className={style.__isMobile}
                  >
                    <Image preview={false} src={Msg} width={25} />
                  </li>
                </Badge>

                <li className={style.__isNotif}>
                  <NotificationStudent
                    dataNotif={dataNotif}
                    refetch={refetch}
                    viewNotifByUser={viewNotifByUser}
                    isLoading={isLoading}
                  />
                </li>

                <li
                  className={style.__isMobile}
                  onClick={() => setVisible(true)}
                >
                  <Image preview={false} src={MenuIcon} width={24} />
                </li>
              </ul>
            </nav>

          </div>
        </div>
      </header>

      <MenuModal
        visible={visible}
        setVisible={setVisible}
        openDrawer={openDrawer}
        openMessagerieDrawerStudent={openMessagerieDrawer}
        openPaiementDrawer={openPaiementStudentDrawer}
      />

      {!currentChat && (
        <NavUser
          openMessagerieDrawer={openMessagerieDrawer}
          visible={visible}
          setVisible={setVisible}
        />
      )}

      <Modal
        title={<div style={{ fontFamily: "CartoonFont", fontSize: "1.8em", display: "flex", alignItems: "center", justifyContent: "center" }}>Demander un budget</div>}
        visible={visibleDemandModal}
        onCancel={handleCancel}
        footer={null}
        closable={true}
      >
        <p style={{ fontFamily: "CartoonFont", fontSize: "1.8em" }}>Tu peux demander à ton tuteur de recharger ton compte</p>
        <Form
          validateTrigger={["onFinish"]}
          name="registerParent"
          autoComplete="off"
          form={formInfos}
          onFinish={sendDemand}
        >
          <div style={{ border: "1px solid #C0C0C0", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px", marginBottom: "20px", }}>
            <AutoComplete
              bordered={false}
              style={{ width: "100%" }}
              onChange={OnSelectUser}
              placeholder="Rechercher par Nom ou Email"
            >
              {users.map((user: any, i: number) => (
                <Option key={i} value={user?.firstname}>
                  <div
                    onClick={async () => {
                      setDemandTeacherId(user?._id);
                    }}
                  >
                    <Row justify="space-between">
                      <Col>
                        <span style={{ textTransform: "capitalize" }}>
                          {" "}
                          {`${user?.pseudo || ""} ( ${user?.lastname || ""} ${user?.firstname || ""
                            } )`}
                        </span>
                      </Col>
                      <Col>
                        <Avatar src={user?.profile || TeacherIcon} />
                      </Col>
                    </Row>
                  </div>
                </Option>
              ))}
            </AutoComplete>
          </div>

          <Form.Item
            name="montant"
            rules={[{ required: true }]}
            style={{ marginBottom: "20px" }}
          >
            <Input
              type="number"
              className={s.field}
              placeholder={"Budget à recharger €"}
              maxLength={30}
            />
          </Form.Item>

          <Form.Item
            name="message"
            rules={[{ required: true }]}
            style={{ marginBottom: "20px" }}
          >
            <Input.TextArea
              className={s.field}
              placeholder={"Ajouter un message ( optionnel )"}
              maxLength={255}
              rows={3}
            />
          </Form.Item>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <Form.Item>
              <AppButton loading={isMessageLoading} htmlTypeSubmit={true}>Envoyer ma demande</AppButton>
            </Form.Item>
          </div>
        </Form>
      </Modal>

      <Modal
        title={<div style={{ fontFamily: "CartoonFont", fontSize: "1.8em", display: "flex", alignItems: "center", justifyContent: "center" }}>Paramètres avancés</div>}
        visible={visibleDissociationModal}
        onCancel={handleCancelDissociation}
        footer={null}
        closable={true}
        destroyOnClose={true}
      >
        {/* <p style={{ fontSize: "1.2em" }}>Tu peux convertir ton compte en compte normal, attention, ton compte va être déconnecté de {user?.organisationName}</p> */}
        {/* <p style={{ fontSize: "1.2em" }}>Tu vas perdre : ton solde, tes profs recommandés<br />Tu vas garder : Tes points et ton classement</p> */}
        <p style={{ fontSize: "1.2em" }}>En passant à un compte normal, vous pourrez gérer votre profil en toute autonomie.<br />Votre administrateur ne pourra plus accéder à votre tableau de bord, ni recharger votre compte. Vous aurez le contrôle total de votre expérience sur la plateforme!</p>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
          {!isMessageLoading && <Button style={{ borderRadius: "8px", color: "white", backgroundColor: "green", marginRight: "20px", height: "50px" }} onClick={handleCancelDissociation}>Annuler</Button>}
          {!isMessageLoading && <Button style={{ borderRadius: "8px", color: "white", backgroundColor: "red", height: "50px" }} onClick={() => dissociateAccount()}>Confirmer l'opération</Button>}
          <Spin spinning={isMessageLoading} size="large" />
        </div>
      </Modal>

    </>
  );
};

const WhenNotConnected: FC = () => {
  //!HOOKS
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <header className={s.app__header}>
      <Container>
        <div className={s.nav}>
          <div className={s.logo}>
            <Link to="/">
              <Image preview={false} width={150} src={logo} alt="logo" />
            </Link>
          </div>

          <ul className={s.nav__links}>
            <li className={s.nav__item}>
              <a href="tel:00212522334455">
                <Image preview={false} width={20} src={call} alt="logo" />
                05 22 33 44 55
              </a>
            </li>
            <li className={s.nav__item}>
              <a href="">contact</a>
            </li>
            <li className={s.nav__item}>
              <Link to={appRoutes.LOGINS}> Se connecter</Link>
            </li>
          </ul>
        </div>
      </Container>
    </header>
    // <Space align="center">
    //     <Button onClick={() => navigate(appRoutes.LOGIN)}>{t('auth.login.loginTitle')}</Button>
    //     <Button onClick={() => navigate(appRoutes.REGISTER)}>{t('auth.register.registerTitle')}</Button>
    // </Space>
  );
};

export default UserStudent;
