import {
  DeleteOutlined,
  PlusOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, message, Popconfirm, Row } from "antd";
import AppButton from "components/libs/button";
import { FC, useCallback, useEffect, useState } from "react";

//!STYLE
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import s from "../assets/adds.module.css";

//!ICONS
import AppDrawer from "components/appDrawer";
import Matiers from "components/matiers";
import { displayDrawer } from "store/adventissements";
import { setIsCalendarUpdated } from "store/app/appSlice";
import {
  useCreateAdMutation,
  useGetAdQuery,
  useUpadateAdMutation,
} from "store/services/teacher/ads.services";
import { translateDay } from "utils/formats";
import DrawerAdsCreneau from "./drawerAdsCreneau";

/* interface Niche {
  _id: string;
  date: string;
  level: { _id: string; title: string; levelGroup?: string }[];
  topic: { _id: string; title: string; level: string }[];
  timeRanges: { range: { hour: string; allow: boolean }[] }[];
  idCreneau: string;
} */

/* interface Data {
  _id: string;
  niches: Niche[];
} */

/* const days = [
  { name: "Monday", value: "Monday" },
  { name: "Tuesday", value: "Tuesday" },
  { name: "Wednesday", value: "Wednesday" },
  { name: "Thursday", value: "Thursday" },
  { name: "Friday", value: "Friday" },
  { name: "Saturday", value: "Saturday" },
  { name: "Sunday", value: "Sunday" },
]; */

type DayOfWeek = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday';

const dayTranslations: Record<DayOfWeek, string> = {
  "Monday": "Lundi",
  "Tuesday": "Mardi",
  "Wednesday": "Mercredi",
  "Thursday": "Jeudi",
  "Friday": "Vendredi",
  "Saturday": "Samedi",
  "Sunday": "Dimanche"
};

interface Item {
  date: DayOfWeek;
  levels: any[];
  topics: any[];
  range: any[];
  type: string;
  [key: string]: any; // This allows additional properties on the item object
}

const DrawerAds: FC = () => {

  //!HOOKS
  const { t } = useTranslation();
  const { drawerVisible } = useSelector((state) => state.drawerAds);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [createAd, { isLoading, isSuccess, isError }] = useCreateAdMutation();
  const { data: crenaux } = useGetAdQuery(auth.user?._id);
  const [
    upadateAd,
    { isSuccess: isSuccessAd, isLoading: isLoadingAd, isError: isErrorAd },
  ] = useUpadateAdMutation();

  const [form] = Form.useForm();

  //!STATE
  const [openDrawerAdsCreneau, setOpenDrawerAdsCreneau] = useState<boolean>(false);
  const [keyCreneau, setKeyCrenau] = useState<string | undefined>("");
  const [allData, setAllData] = useState<any[]>([]);
  const [currCreneaux, setCurrCreneaux] = useState<any[]>([]);
  const { refetch } = useGetAdQuery(auth.user?._id);

  /* const [isDelete, setIsDelete] = useState<boolean>(false); */

  const showDrawerDrawerAdsCreneau = useCallback((key?: string) => {
    setKeyCrenau(key);
    setOpenDrawerAdsCreneau(true);
  }, []);

  const removeDuplicateRange = (myArray: any) => {
    return (
      myArray.filter((item: any, index: number, self: any) => {
        const rangeStr = JSON.stringify(item.range);
        const dateStr = item.date;

        return (
          index ===
          self.findIndex((obj: any) => {
            return (
              JSON.stringify(obj.range) === rangeStr && obj.date === dateStr
            );
          })
        );
      }) || []
    );
  };

  const days = [
    { label: "Lundi", value: "Lundi" },
    { label: "Mardi", value: "Mardi" },
    { label: "Mercredi", value: "Mercredi" },
    { label: "Jeudi", value: "Jeudi" },
    { label: "Vendredi", value: "Vendredi" },
    { label: "Samedi", value: "Samedi" },
    { label: "Dimanche", value: "Dimanche" },
  ];

  const handleAdd = (day: any) => {
    showDrawerDrawerAdsCreneau(day);
  };

  const isDataEmpty = removeDuplicateRange(allData).length <= 0;

  const onFinish = useCallback(async () => {

    if (isDataEmpty) {
      const errorMsg = "Impossible d'ajouter un créneau vide";
      message.error(errorMsg);
      throw new Error(errorMsg);
    }

    const finalData = {
      niches: removeDuplicateRange(allData)
        .reduce((acc: any, curr: any) => {
          const index = acc.findIndex((x: any) => x.date === curr.date);
          if (index > -1) {
            acc[index] = {
              ...acc[index],
              items: [...acc[index].items, curr],
            };
            return acc;
          }
          return [
            ...acc,
            {
              date: curr.date,
              items: [curr],
            },
          ];
        }, [])
        .map((niche: any) => {
          return {
            date: translateDay(niche?.date),
            level: niche?.items.map((level: any) => level?.levels[0]?.value),
            topic: niche?.items.map((topic: any) => topic?.topics[0]?.value),
            timeRanges: niche?.items.map((r: any) => {
              return {
                range: [...r?.range],
                type: niche?.items.map((t: any) => t?.type)[0],
              };
            }),
          };
        }),
      teacherId: auth.user._id,
    };

    if (currCreneaux?.length >= 0) {
      const finalUpdateData = {
        /// currCreneaux,
        finalData,
        calendarId: crenaux?.data[0]?._id,
      };

      upadateAd(finalUpdateData);
      /// refetch();
    } else {
      createAd(finalData);
      /// refetch();
    }
  }, [allData]);

  useEffect(() => {
    const currCreneaux = crenaux?.data[0]?.niches.map((niche: any) => {
      return {
        date: niche?.date,
        level: niche?.level?.map((l: any) => l?._id),
        topic: niche?.topic?.map((t: any) => t?._id),
        timeRanges: niche?.timeRanges.map((r: any) => {
          return {
            range: [
              {
                hour: r?.range[0].hour,
              },

              { hour: r.range[r?.range.length - 1].hour },
            ],
            type: r?.type,
          };
        }),
      };
    });
    setCurrCreneaux(currCreneaux);
  }, [drawerVisible]);

  useEffect(() => {
    if (isSuccess || isSuccessAd) {
      message.success("créé avec succès !");
      setAllData([]);
      dispatch(displayDrawer(false));

      setTimeout(() => {
        dispatch(setIsCalendarUpdated(true));
      }, 3000);
    }
    if (isError || isErrorAd) {
      message.error("Une erreur inconu a été survenue");
    }
  }, [isSuccess, isError, isSuccessAd, isErrorAd]);

  const handleDelete = (index: number) => {
    let newData = [...allData];
    newData = removeDuplicateRange(newData);
    newData.splice(index, 1);
    setAllData(newData);
  };

  const renderDay = (day: any) => {
    return (
      <div key={day.value} style={{ width: "auto", marginRight: "10px" }}>
        <Button type="primary">{day.label}</Button>
        {removeDuplicateRange(allData)?.map((item: any, index: number) => {
          if (item.date !== day.value) {
            return null;
          }

          return (
            <div key={index} className={s.box}>
              <div>
                {item?.range[0].hour} &gt; {item?.range[1].hour}
              </div>
              <Matiers
                className={s.matiers}
                cours={item?.topics[0]?.children}
                level={item?.levels[0]?.label}
              />

              <div>
                {item?.type === "collectif" ? (
                  <UsergroupAddOutlined />
                ) : (
                  <UserOutlined />
                )}
              </div>

              <div className={s.delete___} onClick={() => handleDelete(index)}>
                <DeleteOutlined style={{ color: "red", fontWeight: 600 }} />
              </div>
            </div>
          );
        })}
        <div className={s.add___} onClick={() => handleAdd(day.value)}>
          <PlusOutlined style={{ color: "#fff", fontWeight: 600 }} />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (crenaux?.data?.length > 0) {
      const calendarData = crenaux.data[0].niches.flatMap((item: any) => {
        // Check if timeRanges has more than one element

        const transformLevels = (levels: any[]) => {
          return levels.map((level: any) => ({
            value: level._id,  // Rename _id to value
            label: level.title,  // Rename title to label
            // Exclude levelGroup, createdAt, updatedAt, __v
          }));
        };

        const transformTopics = (topics: any[]) => {
          return topics.map((topic: any) => ({
            children: topic.title,  // Rename title to children
            key: topic._id,  // Rename _id to key
            value: topic._id,  // Rename _id to value
            // Exclude levelGroup, createdAt, updatedAt, __v
          }));
        };

        if (item.timeRanges && item.timeRanges.length > 1) {
          // Create separate entries for each timeRange
          return item.timeRanges.map((timeRange: any, index: number) => {
            // Flatten the current time range and only keep the first and last element
            const flatTimeRange = [timeRange.range[0], timeRange.range[timeRange.range.length - 1]].map((slot: any) => ({
              hour: slot.hour
              // Remove 'allow' property
            }));

            // Ensure levels and topics match the current index of timeRange
            const correspondingLevel = item.level[index % item.level.length];
            const correspondingTopic = item.topic[index % item.topic.length];

            // Build the new item with the separated time range
            return {
              ...item,
              levels: transformLevels([correspondingLevel]),   // Set the current level according to index
              topics: transformTopics([correspondingTopic]),  // Set the current topic according to index
              range: flatTimeRange,  // Add the modified time range (first and last element only)
              type: timeRange.type,  // Add the type (e.g., 'collectif' or 'individuel')
            };
          });
        } else {
          // If only one time range, just create one new item and only keep the first and last element
          const flatTimeRange = [item.timeRanges[0].range[0], item.timeRanges[0].range[item.timeRanges[0].range.length - 1]].map((slot: any) => ({
            hour: slot.hour
            // Remove 'allow' property
          }));

          return {
            ...item,
            levels: transformLevels(item.level),   // Rename level to levels
            topics: transformTopics(item.topic),  // Rename topic to topics
            range: flatTimeRange,  // Flattened time ranges (first and last element only)
            type: item.timeRanges[0].type,  // Add the type from the only timeRange
          };
        }
      });

      // Check if calendarData is defined and is an array
      if (calendarData && Array.isArray(calendarData)) {
        // Clean up the unwanted fields
        calendarData.forEach((newItem: any) => {
          delete newItem.level;
          delete newItem.topic;
          delete newItem.timeRanges;
          delete newItem.idCreneau;
        });

        // Translate date values to French
        const translatedData = calendarData.map(item => ({
          ...item,
          date: dayTranslations[item.date as DayOfWeek] || item.date  // Translate the date or leave it unchanged if not found
        }));

        setAllData(translatedData);
      }
    }
  }, [crenaux]);

  const [confirmVisible, setConfirmVisible] = useState(false); // Control Popconfirm visibility

  const handleClose = () => {
    // Show the popconfirm when the user clicks the close button

    const isData = removeDuplicateRange(allData).length > 0;

    if (isData) {
      setConfirmVisible(true);
      /* message.warning("Le planning n'est pas enregisté"); */
    }
    else {
      dispatch(displayDrawer(false));
      setConfirmVisible(false);
    }
  };

  const handleSave = () => {
    // Handle save logic here
    message.success("Clique sur confirmer le planning");
    setConfirmVisible(false);
  };

  const handleDiscard = () => {
    // Handle discard logic
    dispatch(displayDrawer(false)); // Close the drawer
    setConfirmVisible(false);
  };

  return (
    <AppDrawer
      title={
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", textAlign: "center", margin: "var(--mb-1) 0 0 0" }}>
          {/* Submit button aligned at the top */}
          {/* <AppButton
            disabled={isDataEmpty}
            className={s.button__submit}
            onClick={() => {
              const form = document.getElementById("availabilityForm") as HTMLFormElement;
              form?.submit();
            }}
            loading={isLoading || isLoadingAd}
            style={{ marginLeft: "8%" }} // Align the button to the right
          >
            Confirmer le planning
          </AppButton> */}
          <div style={{ textAlign: "center", marginLeft: "9%", marginTop: "5px" }}>
            <h1 style={{ fontSize: "32px", marginTop: "10px" }}>Ajouter mes disponibilités</h1>
          </div>

        </div>
      }
      onClose={handleClose}
      visible={drawerVisible}
      style={{ maxWidth: "100%" }}
      maskClosable={false}
    >
      {/* Popconfirm */}
      <Popconfirm
        title="Veux-tu quitter sans enregistrer les modifications ?"
        visible={confirmVisible}
        onConfirm={handleSave}
        onCancel={handleDiscard}
        okText="Non"
        cancelText="Oui"
      />
      <div>
        <Form id="availabilityForm" name="control-hooks" onFinish={onFinish}>

          <div className={s.__form}>
            {days.map((day) => renderDay(day))}
          </div>

          <Row justify="center">
            <Col>
              <AppButton
                disabled={isDataEmpty}
                className={s.button__submit}
                htmlTypeSubmit={true}
                loading={isLoading || isLoadingAd}
              >
                Confirmer le planning
              </AppButton>
            </Col>
          </Row>
        </Form>

        <div className={s.__teacher__MAA} />
      </div>

      <DrawerAdsCreneau
        showDrawerDrawerAdsCreneau={showDrawerDrawerAdsCreneau}
        openDrawerAdsCreneau={openDrawerAdsCreneau}
        keyCreneau={keyCreneau}
        setAllData={setAllData}
        allData={allData}
        setOpenDrawerAdsCreneau={setOpenDrawerAdsCreneau}
      />
    </AppDrawer>
  );
};

export default DrawerAds;
