import {
  DeleteOutlined,
  FormOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Upload,
  UploadProps,
  message,
} from "antd";
import ContractIcon from "assets/images/icons/contract.svg";
import VideoIcon from "assets/images/icons/film.svg";
import DocumentIcon from "assets/images/icons/google-docs.svg";
import PhotoIcon from "assets/images/icons/photo.svg";
import AvatarTeacher from "assets/images/teacher/teacher.svg";
import axios from "axios";
import { CKEditor } from "ckeditor4-react";
import { HandlerInputError } from "common/enums";
import AppButton from "components/libs/button";
import { motion } from "framer-motion";
import React, { useRef, useState } from "react";
import ImageUploading from "react-images-uploading";
import { useSelector } from "react-redux";
import {
  useAddPostsMutation,
  useGetPostsCountsQuery,
  useGetPostsQuery
} from "store/services/teacher/activities.services";

import s from "./style.module.css";

const API_BASE_URL = "https://api-ressources.monamialbert.com/api/v1";

const postFormData = async (formData: FormData) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(`${API_BASE_URL}/posts`, formData, config);
};

interface MainContentTopProps {
  onAddPostTop: () => void;
  handleAddDocsTop: (param: any) => void;
}

const MainContentTop: React.FC<MainContentTopProps> = ({ onAddPostTop, handleAddDocsTop }) => {
  const imageUploadRef = useRef<HTMLButtonElement>(null);
  const videoUploadRef = useRef<HTMLDivElement>(null);
  const fileUploadRef = useRef<HTMLDivElement>(null);

  const [addPosts, { data, isLoading, isError, isSuccess }] =
    useAddPostsMutation();
  const [open, setOpen] = useState<boolean>(false);
  const [isArticle, setIsArticle] = useState<boolean>(false);
  const [content, setContent] = useState<string>("");
  const [images, setImages] = React.useState<any[]>([]);
  const [documents, setDocuments] = React.useState<any[]>([]);
  const [videos, setVideos] = React.useState<any[]>([]);
  const { user } = useSelector((state) => state.auth);
  const [loader, setLoader] = useState<boolean>(false);
  const { refetch } = useGetPostsQuery(user?._id);
  const [form] = Form.useForm();
  const maxNumber = 69;
  const { refetch: refetchPostCounts } = useGetPostsCountsQuery(user?._id);

  const showUserModal = () => {
    setOpen(true);
  };

  const addPost = () => {
    setIsArticle(true);
    showUserModal();
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  const iconData = [
    {
      icon: (
        <motion.img
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.9 }}
          src={PhotoIcon}
          width={20}
          alt={"Photo"}
          style={{ position: "relative", top: isMobile ? "0px" : "-3px" }}
        />
      ),
      width: 20,
      text: "Photo",
      onClick: () => imageUploadRef.current?.click(),
    },
    {
      icon: (
        <motion.img
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.9 }}
          src={VideoIcon}
          width={20}
          alt={"Vidéo"}
          style={{ position: "relative", top: isMobile ? "0px" : "-3px" }}
        />
      ),
      width: 20,
      text: "Vidéo",
      onClick: () => {
        setOpen(true);
        setTimeout(() => {
          videoUploadRef.current?.click();
        }, 500);
      },
    },
    {
      icon: (
        <motion.img
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.9 }}
          src={DocumentIcon}
          width={14}
          alt={"Documents"}
          style={{ position: "relative", top: isMobile ? "0px" : "-3px" }}
        />
      ),
      width: 14,
      text: "Documents",
      onClick: () => {
        setOpen(true);
        setTimeout(() => {
          fileUploadRef.current?.click();
        }, 500);
      },
    },
    {
      icon: (
        <motion.img
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.9 }}
          src={ContractIcon}
          width={17}
          alt={"Rédiger un article"}
          style={{ position: "relative", top: isMobile ? "0px" : "-3px" }}
        />
      ),
      width: 17,
      text: "Article",
      onClick: () => {
        setIsArticle(true);
        showUserModal();
      },
    },
  ];

  const onChange = (imageList: any, addUpdateIndex: any) => {
    setImages(imageList);
    setOpen(true);
  };

  const onFinish = async () => {
    try {
      setLoader(true);

      const formData = new FormData();
      formData.append("content", content);
      formData.append("profId", user?._id);
      formData.append("links", images[0]?.file);

      documents.forEach((document: any) => {
        formData.append("links", document?.originFileObj);
      });

      videos.forEach((video: any) => {
        formData.append("links", video?.originFileObj);
      });

      const response = await postFormData(formData);

      setContent("");
      setImages([]);
      setIsArticle(false);
      setDocuments([]);
      setVideos([])
      message.info("Publication réussie");
      form.resetFields();
      refetch();
      refetchPostCounts();
      onAddPostTop();
      handleAddDocsTop(documents.length + videos.length)
      setTimeout(() => {
        setOpen(false);
      }, 500);

      return response.data;
    } catch (error) {
      throw new Error(
        "Une erreur s'est produite lors du téléchargement du fichier."
      );
    } finally {
      setLoader(false);
    }
  };

  const uploadProps: UploadProps = {
    name: "file",
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        info.file.status = "done";
      }

      const filteredJson = info.fileList.map((item) => {
        const { error, response, ...rest } = item;
        return rest;
      });

      setDocuments(filteredJson);
      setOpen(true);
    },
  };

  const uploadPropsVideo: UploadProps = {
    name: "video",
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} video uploaded successfully`);
      } else if (info.file.status === "error") {
        info.file.status = "done";
      }

      const filteredJson = info.fileList.map((item) => {
        const { error, response, ...rest } = item;
        return rest;
      });

      setVideos(filteredJson);
      setOpen(true);
    },
  };

  return (
    <div style={{}}>
      <Row style={{}}>
        <Col span={2}>
          {" "}
          <div>
            <Avatar size={40} src={user?.profile || AvatarTeacher} />
          </div>
        </Col>
        <Col span={22}>
          {" "}
          <div className={s.post}>
            <div className={s.input} onClick={showUserModal}>
              <Form.Item name="content" rules={[{ required: false }]} noStyle>
                <Input
                  style={{ cursor: "pointer" }}
                  bordered={false}
                  placeholder={
                    content ? "Continuer le post..." : "Commencer un post"
                  }
                  onClick={showUserModal}
                  onChange={showUserModal}
                />
              </Form.Item>
            </div>

            <div className={s.postContainer}>
              {iconData.map((item, index) => (
                <Col key={index}>
                  <Space onClick={item?.onClick} className={s.postButton}>
                    {item.icon}
                    <span>{item.text}</span>
                  </Space>
                </Col>
              ))}
            </div>
            <Modal
              title={false}
              visible={open}
              okText="Publier"
              cancelText="Fermer"
              onCancel={() => { setOpen(false); setIsArticle(false); setContent(''); setImages([]); setDocuments([]); setVideos([]); form.resetFields(); }}
              width={isArticle ? 1000 : 700}
              centered
              footer={false}
            >
              <Form
                form={form}
                layout="vertical"
                name="userForm"
                onFinish={onFinish}
              >
                {images?.[0]?.data_url && (
                  <div className={s.img__preview}>
                    <img src={images?.[0]?.data_url} alt="" />
                  </div>
                )}

                {videos.length !== 0 ? (
                  <div className={s.img__preview}>
                    <video className={s.img__preview} controls>
                      <source src={URL.createObjectURL(videos[videos.length - 1].originFileObj)} className={s.img__preview} />
                    </video>
                  </div>
                ) : (
                  <Upload
                    {...uploadPropsVideo}
                    accept=".mov,.mp4"
                    maxCount={1}
                  >
                    <div ref={videoUploadRef} />
                  </Upload>
                )}

                <div className={s.input} style={{ marginTop: "2em" }}>
                  {isArticle ? (
                    <Form.Item
                      name={"content"}
                      valuePropName="data"
                      getValueFromEvent={(event) => {
                        const data = event?.editor?.getData();
                        setContent(data);
                        return data;
                      }}
                      rules={[
                        {
                          required: true,
                          message: HandlerInputError.content,
                        },
                      ]}
                    >
                      <CKEditor
                        initData={content}
                        config={{
                          language: 'fr',
                        }}
                        style={{
                          border: "none",
                        }}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      name={"content"}
                      rules={[
                        {
                          required: true,
                          message: HandlerInputError.content,
                        },
                      ]}
                    >
                      <Input.TextArea
                        rows={10}
                        bordered={false}
                        onChange={(v) => setContent(v.target.value)}
                      />
                    </Form.Item>
                  )}
                </div>

                <Row gutter={12}>
                  <Col>
                    {isArticle && (
                      <Button
                        onClick={() => {
                          setOpen(false);
                          setIsArticle(false);
                          setContent("");
                          form.resetFields();
                        }}
                      >
                        Annuler l'article
                      </Button>
                    )}
                  </Col>

                  <Col>
                    <Upload
                      {...uploadProps}
                      accept="application/pdf"
                      maxCount={5}
                    >
                      <div ref={fileUploadRef} />
                      <Button icon={<UploadOutlined />}>
                        Upload de document
                      </Button>
                    </Upload>
                  </Col>

                  <Col>
                    {images?.[0]?.data_url && (
                      <Button
                        type="primary"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => setImages([])}
                      >
                        Supprimer image
                      </Button>
                    )}
                  </Col>

                  <Col>
                    {videos.length !== 0 && (
                      <Button
                        type="primary"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => setVideos([])}
                      >
                        Supprimer la video
                      </Button>
                    )}
                  </Col>
                </Row>

                <Row justify="center" style={{ marginTop: "2em" }}>
                  <Form.Item>
                    <AppButton htmlTypeSubmit={true} loading={loader}>
                      Publier
                    </AppButton>
                  </Form.Item>
                </Row>
              </Form>
            </Modal>
          </div>
        </Col>
      </Row>

      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        // maxFileSize={2}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          <React.Fragment>
            <button
              ref={imageUploadRef}
              type="button"
              className={s.btn__Upload}
              style={
                isDragging
                  ? { color: "red" }
                  : { position: "absolute", left: "-100%" }
              }
              onClick={() => {
                imageList[0]?.data_url ? onImageUpdate(0) : onImageUpload();
              }}
              {...dragProps}
            >
              <FormOutlined
                style={{
                  fontWeight: "600 !important",
                  color: "var(--first-color)",
                  fontSize: "15px",
                }}
              />
            </button>
          </React.Fragment>
        )}
      </ImageUploading>
    </div>
  );
};

export default MainContentTop;
