import { PlusOutlined } from "@ant-design/icons";
import { Avatar, Col, Divider, message, Popconfirm, Row, Space, Spin } from "antd";
import AppButton from "components/libs/button";
import React, { FC, Fragment, useCallback, useEffect, useRef, useState } from "react";
import TEACHER from "../../../assets/images/teacher/teacher.svg";

//
import Matiers from "components/matiers";
import { badRequest } from "messages";
import { useDispatch, useSelector } from "react-redux";
import { displayDrawerAdsSpecific, setCurrentCreneau } from "store/adventissements";
import { setIsCalendarUpdated } from "store/app/appSlice";
import { useDeleteTeacherAdSpecificMutation } from "store/services/teacher/ads.services";
import s from "./style.module.css";

type Props = {
  cren: any;
  currentTeacher: any;
  confirmReservations: any;
  index: number;
  isAllReservation: boolean;
  setConfirmRes: React.Dispatch<React.SetStateAction<any[]>>;
};

const CardCreneau: FC<Props> = ({
  cren,
  index,
  currentTeacher,
  isAllReservation = false,
  confirmReservations,
  setConfirmRes,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const dateRef = useRef<(HTMLDivElement | null)[]>([]);
  const refs = useRef<(HTMLDivElement | null)[]>([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const setRef = (index: number, element: HTMLDivElement) => {
    refs.current[index] = element;
  };

  const [deleteTeacherAdSpecific, { isLoading, isError, isSuccess }] = useDeleteTeacherAdSpecificMutation();

  const handleClick = useCallback((creneauData: any) => {
    setLoading(true);
    const finalData = {
      teacherId: user?._id,
      niches: {
        topic: [creneauData?.topic?._id],
        level: [creneauData?.level?._id],
        date: creneauData.day,
        timeRanges: [
          {
            range: [
              {
                hour: creneauData?.timeRanges[0],
              },
              {
                hour: creneauData?.timeRanges[1],
              },
            ],
            type: creneauData?.creneauTypes,
          },
        ],
      },
    };
    deleteTeacherAdSpecific(finalData);
  },
    [user]
  );

  const showPopconfirm = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (isSuccess) {
      message.success("Créneau supprimé avec succès");
      setTimeout(() => {
        dispatch(setIsCalendarUpdated(true));
        setLoading(false);
        setVisible(false);
      }, 5000);
    }
    if (isError) {
      message.error(badRequest.error);
    }
  }, [isSuccess, isLoading, isError]);

  const handleChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: any
  ) => {
    let prev = confirmReservations;
    let itemIndex = prev.indexOf(item);
    if (itemIndex !== -1) {
      prev.splice(itemIndex, 1);
    } else {
      prev.push(item);
    }
    setConfirmRes([...prev]);
  };

  const renderElement = (cren: any) => {

    let reservationFound = false
    if (cren?.reservations.length > 0) {
      reservationFound = true
    }

    // if reservationFound = true => show button Meet
    if (reservationFound) {
      return <div style={{ width: "100%" }}>
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          {cren?.status !== "deleted" && <AppButton
            icon={<PlusOutlined />}
            onClick={() => dispatch(displayDrawerAdsSpecific(true))}
            style={{ marginRight: "10px", padding: "0px" }}
          >
            Replanifier
          </AppButton>}
          {cren?.status === "deleted" ? <div style={{ color: "red", fontSize: "14px", fontWeight: "400", display: "flex", alignItems: "center", justifyContent: "center" }}>Créneau annulé</div> : <div>
            <a
              href={cren?.reservations[0]?.studentLink}
              target="_blank"
            >
              <AppButton
                style={{
                  maxWidth: "200px",
                  minWidth: "200px",
                }}
              >
                Rejoindre le meet
              </AppButton>
            </a>
          </div>}
        </div>
      </div>
    } else {
      return <div className={s.content__action__reservation}>
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          {cren?.status !== "deleted" && <AppButton
            icon={<PlusOutlined />}
            onClick={() => {
              dispatch(displayDrawerAdsSpecific(true));
              dispatch(setCurrentCreneau(cren));
            }}
            style={{ marginRight: "10px", padding: "0px" }}
          >
            Replanifier
          </AppButton>}
          {cren?.status === "deleted" ? <div style={{ color: "red", fontSize: "14px", fontWeight: "400", display: "flex", alignItems: "center", justifyContent: "center" }}>Créneau annulé</div> : <Fragment>
            <Popconfirm
              placement="top"
              title={
                "Veux-tu vraiment supprimer cet horaire ?"
              }
              onConfirm={() => handleClick(cren)}
              visible={visible}
              okText={loading ? <Spin size="small" /> : "Oui"}
              cancelText="Non"
              onCancel={handleCancel}
              okButtonProps={{ disabled: loading }}
            >
              <AppButton onClick={showPopconfirm}>
                Supprimer
              </AppButton>
            </Popconfirm>
          </Fragment>}
        </div>
      </div>
    }
  }

  return (
    <div className={s.creneaux} key={index}>
      <div className={s.creneau}>
        <div className={s.card}>
          <div>
            <Row justify="space-between" align="middle" gutter={20}>
              <Col>
                <Avatar.Group
                  maxCount={2}
                  maxStyle={{
                    color: "#f56a00",
                    backgroundColor: "#fde3cf",
                  }}
                >
                  <Avatar
                    size={44}
                    style={{ backgroundColor: "#7fb1b2" }}
                    src={currentTeacher?.teacher?.profile || TEACHER}
                  />

                  {cren?.reservations?._id && (
                    <Avatar
                      size={44}
                      src={user?.profile}
                      style={{ backgroundColor: "#7fb1b2" }}
                    />
                  )}
                </Avatar.Group>
              </Col>
              <Col>
                {cren?.timeRanges?.length > 0 && (
                  <div className={s.range}>
                    <span>{cren?.timeRanges[0]}</span>
                    <span>-</span>
                    <span>{cren?.timeRanges[1]}</span>
                  </div>
                )}
              </Col>

              <Col>
                <Row align="middle">
                  <Space>
                    <div>
                      <span>50 min</span>
                    </div>
                    <div>
                      <span>
                        {typeof cren?.creneauTypes === "string"
                          ? cren?.creneauTypes
                          : cren?.creneauTypes.creneauTypes[0]}
                      </span>
                    </div>
                  </Space>
                  <div className={s.matiere}>
                    <Matiers
                      cours={cren?.level?.title}
                      level={cren?.topic?.title}
                    />
                  </div>
                </Row>
              </Col>
            </Row>
          </div>

          <div>
            <Fragment>
              {renderElement(cren)}
            </Fragment>
          </div>
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default CardCreneau;
