import { FC, Fragment, useEffect, useState } from "react";

//!LIBRARY
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space } from "antd";
import { motion } from "framer-motion";

//!REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  setIdReceiver,
  setSelectUserChat,
  toggleAppChat,
} from "store/AppChat/appChat";
import {
  useFollowMutation,
  useUnFollowMutation,
} from "store/services/teacher/profileTeacher";

//!ASSETS
import FollowIcon from "assets/images/app/Action_Follow.svg";
import FollowedIcon from "assets/images/app/Followed.svg";
import DiscussionIcon from "assets/images/students/discussionIcon.svg";
import EnglishIcon from "assets/images/students/englishIcon.svg";
import FrenchIcon from "assets/images/students/frenchIcon.svg";
import StartIcon from "assets/images/students/teacherStart.svg";
import { default as Teacher, default as TeacherIcon } from "assets/images/teacher/teacher.svg";

//!STYLES
import s from "./assets/style.module.css";

//
import { FLAGS, UserRoles } from "common/enums";
import { Link } from "react-router-dom";
import TeacherCalendarForStudent from "screens/student/components/calendarTeacher";
import IconTopic from "screens/student/searchTeacher/components/iconTopic";
import { closeProfileDrawer } from "store/auth/authSlice";
import { useFindRoomMutation } from "store/services/chat";
import { handleClearNiveau, handleClearTopic } from "utils/formats";

type Props = {
  teacher: any;
  displayButtonProfile?: boolean;
};

const HeaderProfileTeacher: FC<Props> = ({ teacher, displayButtonProfile }) => {
  //!HOOKS
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const closeDrawer = () => dispatch(closeProfileDrawer());
  const [findRoom, { isSuccess: isSuccessRooms }] = useFindRoomMutation();
  const [follow, { isLoading, isSuccess, data, isError }] = useFollowMutation();
  const [open, setOpen] = useState<boolean>(false);
  const [
    unFollow,
    {
      isLoading: isLoadingUnFollow,
      isSuccess: isSuccessUnFollow,
      data: dataUnFollow,
      isError: isErrorUnFollow,
    },
  ] = useUnFollowMutation();

  //!FUNCTIONS
  const handleDisplayTeacher = (idTeacher: string) => {
    const finalData = {
      actor1: user?._id,
      actor2: idTeacher,
      flag: FLAGS.SINGLE_FLAG,
      type: "",
      instaprof: "",
    };

    findRoom(finalData);
    dispatch(setIdReceiver(idTeacher));
  };

  useEffect(() => {
    if (isSuccessRooms) {
      dispatch(setSelectUserChat(true));
      dispatch(toggleAppChat(true));
    }
  }, [isSuccessRooms]);

  const handleFollow = () => {
    const bodyFollow = {
      profId: teacher?.prof?._id,
      studId: user?._id,
    };

    follow(bodyFollow);
  };

  const handleUnFollow = () => {
    const bodyUnFollow = {
      profId: teacher?.prof?._id,
      studId: user?._id,
    };

    unFollow(bodyUnFollow);
  };

  const pathTeacherObj = {
    teacher: teacher?.prof,
  };

  const isStudent = user?.roles?.includes(UserRoles.STUDENT);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  return (
    <Fragment>
      <div className={s.header}>
        <div className={s.content}>
          <div className={s.__leftt}>
            <div className={s.avatar}>
              <img src={teacher?.profile || Teacher} alt="" />
            </div>
            <div className={s.infos}>
              <Space align={isMobile ? "start" : "center"} className={s.nameContent}>
                <h1 style={{ position: "relative", top: "7px" }}>{teacher?.fullName} </h1>

                <div className={s.topButtons}>
                  {displayButtonProfile && (
                    <div
                      style={{ position: "relative", top: "4px", left: "5px" }}
                      onClick={closeDrawer}
                    >
                      <Link to={`/prof/${user?._id}/${user?.firstname ? user?.firstname : "nom"}.${user?.lastname ? user?.lastname : "complet"}`}>
                        <Button size="small" shape="round">
                          Mon profil
                        </Button>
                      </Link>
                    </div>
                  )}
                  {!isStudent && user?._id === teacher?.prof?._id && <Link to={'/teacher/profile/complete'}>
                    <motion.button
                      className={`${s.editProfileButton}`}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.9 }}
                      onClick={closeDrawer}
                    >
                      {" "}
                      <img
                        src={TeacherIcon}
                        alt=""
                        width={18}
                        height={18}
                        style={{
                          position: "relative",
                          top: "-1px",
                          marginRight: "5px",
                        }}
                      />{" "}
                      Modifier mon profil
                    </motion.button>
                  </Link>}
                </div>
              </Space>
              <div className={s.infos__content}>
                <div className={s.infos__content__top}>
                  <div className={s.__content__lang}>
                    <div className={s.__content__lang__row}>
                      {teacher?.prof?.langues?.includes("fr") && (
                        <div>
                          <span>
                            Je parle <img src={FrenchIcon} alt="" />
                          </span>
                        </div>
                      )}

                      {teacher?.prof?.langues?.includes("en") && (
                        <div>
                          <span>
                            I speak <img src={EnglishIcon} alt="" />
                          </span>
                        </div>
                      )}
                    </div>
                  </div>

                  {handleClearTopic(teacher?.matieres)?.map((item: any) => (
                    <div className={s.matiers}>
                      <div className={s.__tag__topic}>
                        <IconTopic data={item} />
                        <span>{item?.title?.substring(0, 4)}</span>
                      </div>
                    </div>
                  ))}
                </div>

                <div className={s.infos__content__footer}>
                  <div
                    style={{ marginBottom: "3px" }}
                    className={s.lang__content}
                  >
                    <span>Niveau :</span>{" "}
                    {handleClearNiveau(teacher?.prof?.nivMat)?.map(
                      (niv: any) => {
                        return (
                          (niv?.niveau?.levelGroup?.includes("Primaire") && (
                            <>
                              <span>Primaire </span> <span>- </span>
                            </>
                          )) ||
                          (niv?.niveau?.levelGroup?.includes("Collège") && (
                            <>
                              <span>Collège </span>
                              <span>- </span>
                            </>
                          )) ||
                          (niv?.niveau?.levelGroup?.includes("Lycée") && (
                            <>
                              <span>Lycée </span>
                              <span>- </span>
                            </>
                          )) ||
                          (niv?.niveau?.levelGroup?.includes("Prépa") && (
                            <>
                              <span>Prépa </span>
                              <span>- </span>
                            </>
                          ))
                        );
                      }
                    )}
                    {teacher?.prof?.nivMat?.length <= 0 && (
                      <>
                        <span>Non renseigné</span>
                        <span>-</span>
                      </>
                    )}
                  </div>

                  <Row gutter={12}>
                    <Col>
                      <div
                        style={{
                          color: "var(--title-color)",
                          fontSize: "12px",
                        }}
                      >
                        <img
                          src={StartIcon}
                          style={{
                            position: "relative",
                            top: "-2px",
                            marginRight: "3px",
                          }}
                          alt=""
                        />{" "}
                        <span style={{ fontWeight: "500" }}>
                          {teacher?.rate || 0}
                        </span>{" "}
                        <span>{`( ${teacher?.rateNum || 0} avis )`}</span>{" "}
                      </div>
                    </Col>

                    <Col>
                      <span style={{ position: "relative", top: "-2px" }}>
                        |
                      </span>
                    </Col>

                    <Col>
                      <div
                        style={{
                          color: "var(--title-color)",
                          fontSize: "12px",
                        }}
                        className={s.follow}
                      >
                        {/* <img
                        src={StudentIcon}
                        style={{
                          position: "relative",
                          top: "-2px",
                          marginRight: "3px",
                        }}
                        alt=""
                        width={20}
                      />{" "} */}
                        {isLoading || isLoadingUnFollow ? (
                          <LoadingOutlined
                            style={{
                              color: "var(--first-color)",
                              marginRight: "3px",
                              fontSize: "17px",
                            }}
                          />
                        ) : (
                          <>
                            {teacher?.alreadyFollowd ? (
                              <motion.img
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.9 }}
                                src={FollowedIcon}
                                alt=""
                                width={17}
                                onClick={() => teacher?.prof?._id !== user?._id ? handleUnFollow() : {}}
                              />
                            ) : (
                              <motion.img
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.9 }}
                                src={FollowIcon}
                                alt=""
                                width={17}
                                onClick={() => teacher?.prof?._id !== user?._id ? handleFollow() : {}}
                              />
                            )}
                          </>
                        )}
                        <span style={{ fontWeight: "500" }}>
                          {teacher?.followsNum || 0}
                        </span>{" "}
                        <span>Follower{`(s)`}</span>{" "}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>

          <div className={s.__right}>
            <div className={s.__right__content}>
              {/* <span>
                À partir de{" "}
                <strong
                  style={{
                    fontWeight: "500",
                    color: "var(--title-color)",
                  }}
                >
                  {teacher?.minNormalPrice}€/heure
                </strong>
              </span> */}

              <div className={s.row__btn}>
                {/* <motion.button
                  className={`${s.button}`}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => setOpen(true)}
                >
                  {" "}
                  <img
                    src={PlanningIcon}
                    alt=""
                    style={{
                      position: "relative",
                      top: "-1px",
                      marginRight: "5px",
                    }}
                  />{" "}
                  Planning des cours
                </motion.button> */}

                {!user || !user.roles.includes("teacher") && (
                  <motion.button
                    className={`${s.button}`}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => handleDisplayTeacher(teacher?.prof?._id)}
                  >
                    <img
                      src={DiscussionIcon}
                      alt=""
                      style={{
                        position: "relative",
                        top: "-1px",
                        marginRight: "5px",
                      }}
                    />{" "}
                    Envoyer un message
                  </motion.button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <TeacherCalendarForStudent
        setOpen={setOpen}
        open={open}
        currentTeacher={pathTeacherObj}
      />
    </Fragment>
  );
};

export default HeaderProfileTeacher;
