import {
  ArrowLeftOutlined,
  LoadingOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Avatar, Result, Row } from "antd";
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import React, { FC, useEffect, useRef, useState } from "react";
import Linkify from "react-linkify";
import { useDispatch, useSelector } from "react-redux";

//!ASSETS
import TeacherIcon from "assets/images/teacher/teacher.svg";
import { ReactComponent as Albert } from "assets/images/teacher/teacherMaa.svg";

import H2 from "components/libs/subtitle";
import moment from "moment";
import { setCurrentChat } from "store/AppChat/appChat";
import PlanningIcon from "../../../../assets/images/students/planning.svg";

//!STYLE
import TeacherCalendarForStudent from "screens/student/components/calendarTeacher";
import DrawerSearchInstaProf from "screens/student/searchTeacher/drawerSearchInstaProf";
import s from "./assets/style/chat.module.css";

type Props = {
  conversation: any;
  isTyping: boolean;
};

const ChatSpace: FC<Props> = ({ conversation, isTyping }) => {
  //!HOOKS
  const dispatch = useDispatch();
  const { idSender, idReceiver, currentChat, startChat } = useSelector(
    (state) => state.appChat
  );
  const { user } = useSelector((state) => state.auth);
  const { isChatInstaProf } = useSelector((state) => state.room);
  const refChat = useRef<any>();

  const [openDrawerInstaProf, setOpenDrawerInstaProf] = useState<boolean>(false);
  const [showDrawerTeacherCalendar, setShowDrawerTeacherCalendar] = useState<boolean>(false);
  const [teacherData, setTeacherData] = useState<any>();
  const [teacherID, setTeacherID] = useState<any>();

  const [isBtnInstaProf, setIsBtnInstaProf] = useState<boolean>(true);
  const showDrawerSearchInstaProf = () => {
    setOpenDrawerInstaProf(!openDrawerInstaProf);
  };

  const onShowDrawerTeacherCalendar = () => {
    setShowDrawerTeacherCalendar(true);
  };

  const getTeacherData = async (teacherId: string) => {
    try {
      const response = await AUTH_SERVICES.getTeacherData(user?._id, teacherId);
      console.log(response?.data);
      setTeacherData(response?.data);
    } catch (error: any) { }
  };

  useEffect(() => {
    refChat?.current?.scrollIntoView({
      behavior: "smooth",
    });
    console.log(currentChat);
    console.log(currentChat?.receiverId?.roles[0]);
    if (currentChat?.receiverId?.roles[0] === "teacher") {
      setTeacherID(currentChat?.receiverId?._id);
      getTeacherData(currentChat?.receiverId?._id);
    }
  }, [conversation, isTyping, currentChat]);

  // console.log(conversation, "conversations?.data");

  return (
    <React.Fragment>
      {/* {currentChat?.instaProf && <ChatTeacherAsk data={currentChat} />} */}
      <div
        className={`${s.chat__space} ${currentChat?.instaProf && s.isInsta}`}
      >
        <div className={s.header}>
          <div
            className={s.back}
            onClick={() => dispatch(setCurrentChat(null))}
          >
            <ArrowLeftOutlined style={{ fontSize: "1em" }} />
          </div>
          <span>
            {startChat ? (
              <React.Fragment>
                {currentChat?.receiverIdC ? (
                  <span>
                    {currentChat?.senderId?.firstname}{" "}
                    {currentChat?.senderId?.lastname}
                  </span>
                ) : (
                  <React.Fragment>
                    Discussion Avec {currentChat?.receiverId?.roles[0] === "student" ? "l'élève" : currentChat?.receiverId?.roles[0] === "teacher" ? "le Prof" : currentChat?.receiverId?.roles[0] === "parent" ? "le parent" : ""}{" "}
                    {user?._id === currentChat?.receiverId?._id ? (
                      <>
                        {currentChat?.senderId?.firstname?.split(" ")[0]}{" "}
                        {/* {currentChat?.senderId?.lastname} */}
                      </>
                    ) : (
                      <>
                        {currentChat?.receiverId?.firstname?.split(" ")[0]}{" "}
                        {/* {currentChat?.receiverId?.lastname} */}
                      </>
                    )}{" "}
                    <Avatar
                      size={34}
                      style={{
                        position: "relative",
                        top: "-2px",
                        left: "2px",
                      }}
                      src={
                        currentChat?.receiverIdC ? (
                          <UsergroupAddOutlined
                            style={{
                              color: "var(--first-color)",
                              fontSize: "26px",
                            }}
                          />
                        ) : user?._id === currentChat?.receiverId?._id ? (
                          currentChat?.senderId?.profile || TeacherIcon
                        ) : (
                          currentChat?.receiverId?.profile || TeacherIcon
                        )
                      }
                    />
                    {currentChat?.receiverId?.roles[0] === "teacher" && <button onClick={onShowDrawerTeacherCalendar} style={{ marginLeft: "10px" }}>
                      <img
                        src={PlanningIcon}
                        alt=""
                        style={{
                          position: "relative",
                          top: "-1px",
                          marginRight: "5px",
                        }}
                      />
                      Calendrier
                    </button>}

                    {currentChat?.receiverId?.roles[0] === "teacher" && <button onClick={showDrawerSearchInstaProf} style={{ marginLeft: "10px" }}>
                      instaProf
                    </button>}
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <></>
            )}
          </span>
        </div>
        <div className={s.chat__content}>
          {startChat && conversation.length > 0 ? (
            <React.Fragment>
              {conversation?.map((c: any) => (
                <>
                  <div ref={refChat}>
                    <div
                      className={`${s.chat__msg} ${user?._id === c?.senderId?._id ? s.owner : ""
                        }`}
                    >
                      <div className={s.chat__msg__profile}>
                        <img
                          className={s.chat__msg__img}
                          src={
                            c?.senderId?.profile
                              ? c?.senderId?.profile
                              : TeacherIcon
                          }
                          alt=""
                        />
                        <div className={s.chat__msg__date}>
                          {moment(c?.createdAt).fromNow()}
                        </div>
                      </div>
                      <div className={s.chat__msg__content}>
                        <div className={s.chat__msg__text}>
                          <Linkify>{c?.message}</Linkify>
                        </div>
                        {c?.receiverIdC && (
                          <div>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "var(--first-color)",
                              }}
                            >
                              {c.senderId?.lastname} {c.senderId?.firstname}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ))}

              {isTyping && (
                <div className={`${s.chat__msg}`}>
                  <div className={s.chat__msg__profile}>
                    <img
                      className={s.chat__msg__img}
                      src={
                        user?._id === currentChat?.receiverId?._id
                          ? currentChat?.senderId?.profile || TeacherIcon
                          : currentChat?.receiverId?.profile || TeacherIcon
                      }
                      alt=""
                    />
                    <div className={s.chat__msg__date}>
                      <LoadingOutlined />
                    </div>
                  </div>
                  <div className={s.chat__msg__content}>
                    <div className={s.chat__msg__text}>
                      <span style={{ color: "var(--first-color)" }}>
                        En cours d'écrire
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {startChat ? (
                <Row
                  justify="center"
                  style={{ minHeight: "40vh" }}
                  align="middle"
                >
                  <Result
                    icon={<Albert className={s.Albert} />}
                    title={<H2>Fil de discussion vide </H2>}
                  />
                </Row>
              ) : (
                <Result title={<H2>Choisis une discussion</H2>} />
              )}
            </React.Fragment>
          )}
        </div>
      </div>
      <TeacherCalendarForStudent
        open={showDrawerTeacherCalendar}
        setOpen={setShowDrawerTeacherCalendar}
        currentTeacher={teacherData}
      />

      <DrawerSearchInstaProf
        openDrawerInstaProf={openDrawerInstaProf}
        showDrawerSearchInstaProf={showDrawerSearchInstaProf}
        isBtnInstaProf={isBtnInstaProf}
        setIsBtnInstaProf={setIsBtnInstaProf}
        dataOfTeacher={teacherID}
      />
    </React.Fragment>
  );
};

export default ChatSpace;
