import { PlusOutlined, RedoOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Pagination,
  Row,
  Select,
  Skeleton,
  Steps,
  Tooltip,
} from "antd";
import * as appRoutes from "config/routes.config";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as TOPICS_SERVICES from "../../../api/services/topics/topics.services";

//!IMAGES
import { ReactComponent as SarchIcon } from "../../../assets/images/app/search.svg";

//!STYLES
import H2 from "components/libs/subtitle";
import AppScreen from "../ScreenWithSidebar";
import styles from "./assets/exercices.module.css";

import { StatusCode, TypeExercices } from "common/enums";

//!COMPONENTS
import { fetchCoursForTopic } from "api/services/courses/courses.services";
import AppResult from "components/appResults";
import Breadcrumbs from "components/breadcrumbs";
import AppButton from "components/libs/button";
import Flex from "components/libs/flex";
import AppSelectGroup from "components/libs/selectGroup";
import {
  Icourses,
  useGetAllExercicesMutation,
} from "store/services/teacher/courses";
import CardExercice from "../components/cardExo";
import HeaderTabContent from "../components/headerTabContent";

const { Option } = Select;
const { Step } = Steps;
const { Search } = Input;

const Exercises: FC = () => {
  //!HOOKS
  const dispatch = useDispatch();

  const [
    getAllExercices,
    { data, isLoading: isLoadingExo, isSuccess, isError },
  ] = useGetAllExercicesMutation();
  const [form] = Form.useForm();

  //!STATE
  const [courses, setCourses] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [tempTopic, setTempTopic] = useState<any>();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [course, setCourse] = useState<any>(null);
  const submit = useRef<any>(null);
  const [currentPage, setcurrentPage] = useState<number>(1);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  const filterExercices: any = useCallback(async (values: any) => {
    await getAllExercices(values);
  }, []);

  const OnSelectLevel = useCallback(
    async (levelId: string) => {
      const response = await TOPICS_SERVICES.findAll(levelId);
      console.log(response);
      if (response?.statusCode === StatusCode.OK) {
        setTempTopic(response?.data);
      }
    },
    [tempTopic]
  );

  const OnSelectCours = useCallback(
    async (courseId: any) => {
      setIsLoading(true);
      try {
        if (courseId) {
          const response = await fetchCoursForTopic(courseId);
          if (response?.statusCode === StatusCode.OK) {
            setCourse(response?.data);
            setIsDisabled(false);
            submit.current.click();
          }
        } else {
          submit.current.click();
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    },
    [course, isDisabled]
  );

  const onReset = () => {
    form.resetFields();
    getAllExercices({
      title: "",
      levelId: "",
      topicId: "",
      status: "",
      self: false,
    } as Icourses);
  };

  useEffect(() => {
    getAllExercices({
      title: "",
      levelId: "",
      topicId: "",
      status: "",
      self: false,
      page: currentPage,
      duration: "",
      quizzLevel: ""
    });
    window.scrollTo(0, 0);
  }, [currentPage]);

  return (
    <React.Fragment>
      <Breadcrumbs isMycontent={true} />
      <AppScreen>
        <HeaderTabContent />

        <div className={styles.header}>
          <div className={styles.left}>
            <H2 className={styles.subtitle}>exercices</H2>
          </div>

          <div className={styles.center} />

          {!isMobile && <div className={styles.right}>
            <Link to={appRoutes.TEACHER_CREATE__EXO}>
              <AppButton icon={<PlusOutlined />}>Créer un exercice</AppButton>
            </Link>
          </div>}
        </div>

        <Form
          layout="inline"
          name="control-hooks"
          autoComplete="off"
          onFinish={filterExercices}
          form={form}
        >
          <div className={isMobile ? styles.filter_mobile : styles.filter}>
            <div className="filter__left">
              <Form.Item name="self" valuePropName="checked">
                <Checkbox onChange={() => submit.current.click()}>
                  Mes exercices
                </Checkbox>
              </Form.Item>
            </div>

            <Tooltip placement="left" title={"Réinitialiser tous les filtres"}>
              <Button
                className={styles.__reset__btn}
                onClick={onReset}
                shape="circle"
                htmlType="button"
              >
                <RedoOutlined />
              </Button>
            </Tooltip>

            <Row gutter={12}>
              <Col>
                <div className={styles.filed__search}>
                  <Form.Item name="title">
                    <Search
                      onChange={() => submit.current.click()}
                      placeholder="Tape ici ta recherche"
                      size="large"
                      bordered={false}
                      enterButton={
                        <button type="submit">
                          <SarchIcon
                            style={{
                              position: "relative",
                              top: "3px",
                              left: "10px",
                            }}
                          />
                        </button>
                      }
                    />
                  </Form.Item>
                </div>
              </Col>

              <Col>
                <div className={isMobile ? styles.select_mobile : styles.select}>
                  <Form.Item name="levelId">
                    <AppSelectGroup
                      placeholder="Choisir un niveau"
                      style={{ width: "150px" }}
                      onChange={(value: string) => (
                        OnSelectLevel(value), submit.current.click()
                      )}
                    />
                  </Form.Item>
                </div>
              </Col>

              <Col>
                <div className={isMobile ? styles.select_mobile : styles.select}>
                  <Form.Item name="topicId">
                    <Select
                      placeholder="Choisir une matière"
                      allowClear
                      onChange={(courseId: string) => OnSelectCours(courseId)}
                      //  onChange={(courseId: string) => OnSelectCours(courseId)}
                      bordered={false}
                      style={{ width: "150px" }}
                    >
                      {tempTopic?.map((topic: any) => (
                        <Option key={topic._id} value={topic._id}>
                          {topic.title}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>

              <Col>
                <div className={isMobile ? styles.select_mobile : styles.select}>
                  <Form.Item name="type">
                    <Select
                      placeholder="Choisir un type"
                      allowClear
                      loading={isLoading}
                      disabled={isDisabled}
                      onChange={() => submit.current.click()}
                      bordered={false}
                      style={{ width: "150px" }}
                    >
                      <Option value={TypeExercices.ApplicationsDeCours}>
                        {TypeExercices.ApplicationsDeCours}
                      </Option>
                      <Option value={TypeExercices.Classiques}>
                        {TypeExercices.Classiques}
                      </Option>
                      <Option value={TypeExercices.Annales}>
                        {TypeExercices.Annales}
                      </Option>
                      <Option value={TypeExercices.Entraînement}>
                        {TypeExercices.Entraînement}
                      </Option>
                    </Select>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
          <Button
            ref={submit}
            htmlType="submit"
            className={styles.__submit__hidden}
          ></Button>
        </Form>

        {isLoadingExo && (
          <div>
            <Skeleton active />
            <Skeleton active />
          </div>
        )}
        {!isLoadingExo && data?.data?.result.length > 0 && (
          <Flex>
            {data?.data?.result.map((exercise: any) => (
              <React.Fragment key={exercise._id}>
                <CardExercice exercise={exercise} />
              </React.Fragment>
            ))}
          </Flex>
        )}
        {!isLoadingExo && data?.data?.result.length < 1 && (
          <>
            <Row justify="center">
              <Link to={appRoutes.TEACHER_CREATE__EXO}>
                <AppButton icon={<PlusOutlined />}>Créer un exercice</AppButton>
              </Link>
            </Row>
            <AppResult
              status="info"
              title="Aucun exercice disponible"

            // extra={
            //   <Button
            //     onClick={() => setShowAddExerciseModal(true)}
            //     icon={<PlusOutlined />}
            //     type="primary"
            //   >
            //     Créer un cours{" "}
            //   </Button>
            // }
            />
          </>
        )}

        {!isLoadingExo && (
          <Row justify="center" style={{ marginTop: "2em" }}>
            <Pagination
              showSizeChanger={false}
              onChange={(curr: number) => setcurrentPage(curr)}
              defaultCurrent={currentPage}
              total={data?.data?.count * 10}
            />
          </Row>
        )}
      </AppScreen>
    </React.Fragment>
  );
};

export default Exercises;
