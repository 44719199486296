import {
    DeleteOutlined,
    MinusCircleOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import {
    Button,
    Form,
    Input,
    Select,
    message
} from "antd";
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import { FC, Fragment, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as TOPICS_SERVICES from "../../../api/services/topics/topics.services";

//!IMAGES
import questionPicture from '../../../assets/images/app/question.png';

//!STYLES
import { updateNivMat } from "api/services/advertissements/advertissements.services";
import {
    StatusCode,
} from "common/enums";
import AppButton from "components/libs/button";
import AppSelectGroupInscription from "components/libs/selectGroupInscription";
import H2 from "components/libs/subtitle";
import MAA from "components/maa";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "store/auth/authSlice";
import stl from "./assets/register.module.css";
import st from "./assets/stepTwo.module.css";

const { Option } = Select;

type Props = {
    stepSixData: any;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
};

const StepSeven: FC<Props> = ({ stepSixData, setCurrent }) => {
    //!HOOKS
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);

    //!STATES
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tempTopic, setTempTopic] = useState<any>([]);
    const refAddCreneau = useRef<any>();
    const refAddMatiere = useRef<any>();
    const [userNivMat, setUserNivMat] = useState(
        user?.nivMat
            ? user.nivMat
            : [{}]
    );
    const [open, setOpen] = useState<boolean>(false);

    //!CONSTANTS
    const validateMessages = {
        required: t("auth.error.required"),
        types: {
            email: t("auth.error.emailInvalid"),
        },
    };

    //!STATES JOFREY
    const [stepSevenData, setStepSevenData] = useState<any>({});

    //!FUNCTIONS JOFREY
    const moveToStepEight = async (values: any) => {
        setIsLoading(true);
        setStepSevenData(user);
        const cleanNivMat = values.nivMat.map((niv: any) => {
            return {
                niveau: typeof niv.niveau === "object" ? niv.niveau?._id : niv.niveau,
                matieres: niv?.matieres.map((mat: any) => {
                    return {
                        cost: Number(mat?.cost),
                        costCollectif: Number(mat?.costCollectif),
                        matiere: mat?.matiere?._id || mat?.matiere?.title,
                    };
                }),
            };
        });

        const finalData = {
            //nivMat: cleanNivMat,
            step: user && user?.step === "completed" ? "completed" : "cost",
        };

        const dataOfNivMat = {
            uid: user?._id,
            arrayNivMat: cleanNivMat,
        };

        console.log(finalData);

        try {
            //update array of niveaux/matieres
            const responseDataOfNivMat = await updateNivMat(dataOfNivMat);

            //update just the step
            const response = await AUTH_SERVICES.updateTeacher(user?._id, {
                ...finalData,
            });
            if (response?.statusCode === StatusCode.OK) {
                /* dispatch(
                    updateUser({
                        ...user,
                        nivMat: cleanNivMat,
                        step: user && user?.step === "completed" ? "completed" : "cost"
                    })
                ); */
                updateUserData();
                message.success({
                    content: "Modification effectuée ",
                });
            }
            else {
                message.warning({
                    content: "une erreur est survenue",
                });
            }
        } catch (error: any) {
            let content = "";
            for (
                let index = 0;
                index < error?.response?.data?.message?.length;
                index++
            ) {
                content = error?.response?.data?.message[index] + ", " + content;
            }
            message.error({
                content: content,
            });
        }
    }

    const updateUserData = async () => {
        try {
            const finalUser = await AUTH_SERVICES.getUserByUid(user.uid);
            if (finalUser?.data) {
                dispatch(updateUser(finalUser.data));
                setIsLoading(false);
                setCurrent(8);
            } else {
                throw new Error("L'utilisateur n'existe pas");
            }
        } catch (error) { }
    }

    const OnSelectLevel = useCallback(
        async (levelId: string) => {
            const response = await TOPICS_SERVICES.findAll(levelId);

            if (response?.statusCode === StatusCode.OK) {
                setTempTopic(response?.data);
            }
        },
        [tempTopic]
    );

    //!EFFECTS
    useEffect(() => {
        if (user) {
            if (userNivMat.length === 0) {
                refAddCreneau.current.click();
            }
        }
        else {
            refAddCreneau.current.click();
        }
    }, []);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <section>
            <div style={{ margin: "0px auto", width: "100%" }}>
                <Form
                    onFinish={moveToStepEight}
                    validateTrigger={["onFinish"]}
                    name="registerTeacher"
                    autoComplete="off"
                    validateMessages={validateMessages}
                    initialValues={{ nivMat: userNivMat }}
                >
                    <div className={stl.explanationBox} onClick={() => setOpen(!open)}>
                        <img src={questionPicture} alt="Question Mark" height={25} className={stl.questionMark} />
                        <a className={stl.explanationBoxTitle} style={{ fontFamily: "CartoonFont" }}> EXPLICATION SYSTÈME DE POINTS</a>
                    </div>

                    <div style={{ height: "auto", width: "100%", backgroundColor: "#fff", padding: "15px", marginTop: "30px", alignItems: "flex-start" }}>
                        <div className={st.full__content}>
                            <H2 className={st.title}>Je donne des cours en :</H2>

                            <Form.List name="nivMat">
                                {(fields, { add, remove }) => (
                                    <div>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Fragment>
                                                <div className={st.__content}>
                                                    <div className={st.level}>
                                                        <div className={st.field}>
                                                            <Form.Item
                                                                style={{ margin: 0, padding: 0 }}
                                                                name={[name, "niveau"]}
                                                                rules={[{ required: true }]}
                                                            >
                                                                <AppSelectGroupInscription
                                                                    initValue={user?.nivMat[key]?.niveau?._id}
                                                                    placeholder="Choisir un niveau"
                                                                    onChange={(value: string) => OnSelectLevel(value)}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </div>

                                                    <Form.Item
                                                        style={{
                                                            margin: 0,
                                                        }}
                                                        name={[name, "matieres"]}
                                                        rules={[{ required: true }]}
                                                    >
                                                        <Form.List name={[name, "matieres"]}>
                                                            {(fields, { add, remove }) => (
                                                                <Fragment>
                                                                    {fields.map(({ key, name, ...restField }) => (
                                                                        <div
                                                                            style={{ position: "relative" }}
                                                                            className={st.forms}
                                                                            key={key}
                                                                        >
                                                                            <div className={st.field}>
                                                                                <Form.Item
                                                                                    style={{ margin: 0, padding: 0 }}
                                                                                    {...restField}
                                                                                    name={[name, "matiere", "title"]}
                                                                                    rules={[{ required: true }]}
                                                                                >
                                                                                    <Select
                                                                                        className={st.select}
                                                                                        placeholder="Choisir une matière"
                                                                                        style={{ width: "100%" }}
                                                                                        bordered={false}
                                                                                        allowClear
                                                                                    >
                                                                                        {tempTopic?.map((topic: any) => (
                                                                                            <Option
                                                                                                key={topic._id}
                                                                                                value={topic._id}
                                                                                            >
                                                                                                {topic.title}
                                                                                            </Option>
                                                                                        ))}
                                                                                    </Select>
                                                                                </Form.Item>
                                                                            </div>

                                                                            <div className={st.field}>
                                                                                <Form.Item
                                                                                    style={{ margin: 0, padding: 0 }}
                                                                                    {...restField}
                                                                                    name={[name, "cost"]}
                                                                                    rules={[
                                                                                        {
                                                                                            pattern: /^(?:\d*)$/,
                                                                                            message:
                                                                                                "La valeur doit contenir seulement les chiffre.",
                                                                                        },
                                                                                        {
                                                                                            pattern: /^[\d]{0,3}$/,
                                                                                            message: "Cost max 500 euros",
                                                                                        },
                                                                                        { required: true },
                                                                                    ]}
                                                                                    validateTrigger="onBlur"
                                                                                >
                                                                                    <Input
                                                                                        bordered={false}
                                                                                        type="number"
                                                                                        style={{ width: "100%" }}
                                                                                        placeholder="Tarif individuel"
                                                                                        // addonAfter="€"
                                                                                        suffix="€/séance"
                                                                                    />
                                                                                </Form.Item>
                                                                            </div>

                                                                            <div className={st.field}>
                                                                                <Form.Item
                                                                                    style={{ margin: 0, padding: 0 }}
                                                                                    {...restField}
                                                                                    name={[name, "costCollectif"]}
                                                                                    rules={[
                                                                                        {
                                                                                            pattern: /^(?:\d*)$/,
                                                                                            message:
                                                                                                "La valeur doit contenir seulement les chiffre.",
                                                                                        },
                                                                                        {
                                                                                            pattern: /^[\d]{0,3}$/,
                                                                                            message: "Cost max 500 euros",
                                                                                        },
                                                                                        { required: true },
                                                                                    ]}
                                                                                    validateTrigger="onBlur"
                                                                                >
                                                                                    <Input
                                                                                        bordered={false}
                                                                                        type="number"
                                                                                        style={{ width: "100%" }}
                                                                                        placeholder="Tarif collectif"
                                                                                        // addonAfter="€"
                                                                                        suffix="€/séance"
                                                                                    />
                                                                                </Form.Item>
                                                                            </div>

                                                                            <MinusCircleOutlined
                                                                                style={{
                                                                                    position: "absolute",
                                                                                    right: "-30px",
                                                                                    top: "15px",
                                                                                }}
                                                                                onClick={() => remove(name)}
                                                                            />
                                                                        </div>
                                                                    ))}

                                                                    <Form.Item style={{ margin: 0, padding: 0 }}>
                                                                        <Button
                                                                            ref={refAddMatiere}
                                                                            type="link"
                                                                            className={st.button}
                                                                            icon={<PlusOutlined />}
                                                                            onClick={() => {
                                                                                add();
                                                                            }}
                                                                        >
                                                                            Ajouter
                                                                        </Button>
                                                                    </Form.Item>
                                                                </Fragment>
                                                            )}
                                                        </Form.List>
                                                    </Form.Item>
                                                    <DeleteOutlined
                                                        style={{
                                                            position: "relative",
                                                            color: "red",
                                                            right: "-40px",
                                                            top: "15px",
                                                        }}
                                                        onClick={() => remove(name)}
                                                    />
                                                </div>
                                            </Fragment>
                                        ))}

                                        <Form.Item style={{ margin: 0, padding: 0 }}>
                                            <Button
                                                ref={refAddCreneau}
                                                className={st.__new__creneaux}
                                                type="link"
                                                onClick={() => {
                                                    add();
                                                }}
                                                block
                                                icon={<PlusOutlined />}
                                            >
                                                Ajouter un autre niveau
                                            </Button>
                                        </Form.Item>
                                    </div>
                                )}
                            </Form.List>

                        </div>
                    </div>

                    <div className={stl.rowButtons}>
                        <div className={stl.rowButtonChild}>
                            <AppButton onClick={() => setCurrent((n) => n - 1)}><a className={stl.nextButton}>RETOUR</a></AppButton>
                        </div>
                        <div className={stl.rowButtonChild}>
                            <Form.Item style={{ margin: 0, padding: 0 }}>
                                <AppButton loading={isLoading} htmlTypeSubmit={true}><a className={stl.nextButton}>SUIVANT</a></AppButton>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </div>
            <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignContent: "center", justifyItems: "center", alignItems: "center" }}>
                <MAA open={open} setOpen={setOpen} />
            </div>
        </section>
    );
};

export default StepSeven;
