import { DownOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Dropdown, Menu, message, Popconfirm, Row, Spin, Tag } from "antd";
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import TEACHER from "assets/images/teacher/teacher.svg";
import { StatusCode } from 'common/enums';
import H2 from "components/libs/subtitle";
import Matiers from "components/matiers";
import ScheduleMeeting from 'components/scheduleMeet';
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsReservationsUpdated } from 'store/app/appSlice';
import s from "../assets/calendar.module.css";

type Props = {
  cren: any;
  isHistorique: boolean;
};

const CardReservations: FC<Props> = ({ cren, isHistorique }) => {

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [popconfirmVisible, setPopconfirmVisible] = useState(false);
  const [scheduleData, setScheduleData] = useState<any>(null);
  const [openScheduleMeetingDrawer, setOpenScheduleMeetingDrawer] = useState<boolean>(false);

  const handleMenuClick = (e: any) => {
    console.log('Selected menu item:', e.key);
    if (e.key === "1") {
      console.log('Joining meet');
      openUrlInNewTab();
    } else if (e.key === "2") {
      setPopconfirmVisible(true);
      setDropdownVisible(false);
    }
    else if (e.key === "3") {
      setOpenScheduleMeetingDrawer(true);
      setDropdownVisible(false);
    }
  };

  const handleConfirm = () => {
    console.log('Canceling meet confirmed');
    setPopconfirmVisible(false);
    cancelMeeting();
  };

  const handleCancel = () => {
    console.log('Action cancelled');
    setPopconfirmVisible(false);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">Rejoindre le meet</Menu.Item>
      <Menu.Item key="2">Annuler le meet</Menu.Item>
      <Menu.Item key="3">Replanifier le meet</Menu.Item>
    </Menu>
  );

  const cancelMeeting = async () => {
    setIsLoading(true);
    try {
      const response = await AUTH_SERVICES.cancelMeeting(cren?._id);
      if (response?.statusCode === StatusCode.CREATED) {
        message.success({
          content: "Meet annulé ",
        });
        setTimeout(() => {
          dispatch(setIsReservationsUpdated(true));
        }, 3000);
        setIsLoading(false);
      }
      else {
        message.warning({
          content: "Ooops, erreur lors de l'annulation",
        });
        setIsLoading(false);
      }
    } catch (error: any) {
      let content = "";
      for (
        let index = 0;
        index < error?.response?.data?.message?.length;
        index++
      ) {
        content = error?.response?.data?.message[index] + ", " + content;
      }
      message.error({
        content: content,
      });
      setIsLoading(false);
    }
  }

  const scheduleMeeting = async () => {
    setIsLoading(true);
    try {

      const response = await AUTH_SERVICES.scheduleMeeting(scheduleData);
      if (response?.statusCode === StatusCode.CREATED) {
        message.success({
          content: "Meet replanifié ",
        });
        setTimeout(() => {
          dispatch(setIsReservationsUpdated(true));
        }, 3000);
        setIsLoading(false);
      }
      else {
        message.warning({
          content: "Ooops, erreur lors de la replanification",
        });
        setIsLoading(false);
      }
    } catch (error: any) {
      let content = "";
      for (
        let index = 0;
        index < error?.response?.data?.message?.length;
        index++
      ) {
        content = error?.response?.data?.message[index] + ", " + content;
      }
      message.error({
        content: content,
      });
      setIsLoading(false);
    }
  }

  const openUrlInNewTab = () => {
    window.open(cren?.studentLink, '_blank'); // Replace with your URL
  };

  useEffect(() => {
    console.log(scheduleData);
    if (scheduleData !== null) {
      scheduleMeeting();
    }
  }, [scheduleData]);

  return (
    <>
      <div className={s.creneaux}>
        <div className={s.creneau}>
          <div className={s.card}>
            <div>
              <Row justify="space-between" align="middle" gutter={24}>
                <Col>
                  <Avatar.Group
                    maxCount={2}
                    maxStyle={{
                      color: "#f56a00",
                      backgroundColor: "#fde3cf",
                    }}
                  >
                    <Avatar
                      size={44}
                      style={{ backgroundColor: "#7fb1b2" }}
                      src={cren?.profId?.profile || TEACHER}
                    />
                  </Avatar.Group>
                </Col>
                <Col>
                  <H2>
                    {cren?.profId?.firstname} {cren?.profId?.lastname}
                  </H2>
                </Col>
                <Col>
                  <div className={s.range}>
                    <span>{cren?.range[0]}</span>
                  </div>
                </Col>

                <Col>
                  <span>50 min</span> <span className={s.bar}>|</span>{" "}
                  <Tag color="default">{cren?.price} €</Tag>
                </Col>

                <Col>
                  <div className={s.matiere}>
                    <Matiers
                      style={{ margin: 0 }}
                      cours={cren?.topic?.title}
                      level={cren?.level?.title}
                    />
                  </div>
                </Col>
              </Row>
            </div>

            <div
              style={{
                marginLeft: "auto",
              }}
            >
              <div>
                <Row justify="end">
                  <Col>
                    {/* {isHistorique ? (
                    <Tag icon={<CheckCircleOutlined />} color="success">
                      success
                    </Tag>
                  ) : (
                    <a href={cren?.studentLink} target="_blank">
                      <AppButton
                        style={{
                          maxWidth: "200px",
                          minWidth: "200px",
                        }}
                      >
                        Rejoindre le meet
                      </AppButton>
                    </a>
                  )} */}
                    {/* {!isHistorique && <a href={cren?.studentLink} target="_blank">
                    <AppButton
                      style={{
                        maxWidth: "200px",
                        minWidth: "200px",
                      }}
                    >
                      Rejoindre le meet
                    </AppButton>
                  </a>} */}
                  </Col>
                </Row>
                {isLoading ? <div>
                  <Spin />
                </div> : !isHistorique && <Dropdown overlay={menu} visible={dropdownVisible} onVisibleChange={setDropdownVisible} >
                  <Button onClick={() => setDropdownVisible(true)} className={s.button}>
                    Action <DownOutlined />
                  </Button>
                </Dropdown>}
                {popconfirmVisible && (
                  <Popconfirm
                    title={`Veux-tu annuler ce meet?`}
                    visible={popconfirmVisible}
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                    okText="Oui"
                    cancelText="Non"
                  >
                    <Button type="primary" style={{ display: 'none' }} /> {/* Invisible Button */}
                  </Popconfirm>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScheduleMeeting setScheduleData={setScheduleData} setOpenScheduleMeetingDrawer={setOpenScheduleMeetingDrawer} openScheduleMeetingDrawer={openScheduleMeetingDrawer} id={cren?._id} />
    </>
  );
};

export default CardReservations;
