import { Image } from "antd";
import { FC } from "react";

//!COMPONENTS
import AppButton from "components/libs/button";

//!IMAGES
import Cours from "assets/images/app/Cours.svg";
import Exo from "assets/images/app/Exercices.svg";
import Quiz from "assets/images/app/Quiz.svg";

//!STYLES
import s from "./assets/style.module.css";

type Props = {
  publications: any;
};

const ContributionsProfileTeacherCard: FC<Props> = ({ publications }) => {
  return (
    <div className={s.card__content}>
      <h3>Mes contributions</h3>
      <p>Retrouve mes dernières contributions sur Mon Ami albert</p>

      <div className={s.row}>
        <div className={s.card}>
          <div className={s.type__content}>
            <Image preview={false} width={35} src={Cours} alt="logo" />
          </div>

          <div className={s.cours__number}>
            <div>
              <span>{publications?.mesCours}</span>
            </div>
          </div>

          <AppButton className={`${s.button} `}>{"Mes cours"}</AppButton>
        </div>
        <div className={s.card}>
          <div className={s.type__content}>
            <Image preview={false} width={45} src={Quiz} alt="logo" />
          </div>

          <div className={s.cours__number}>
            <div>
              <span>{publications?.mesQuizz}</span>
            </div>
          </div>

          <AppButton className={`${s.button} `}>{"Mes quiz"}</AppButton>
        </div>
        <div className={s.card}>
          <div className={s.type__content}>
            <Image preview={false} width={35} src={Exo} alt="logo" />
          </div>

          <div className={s.cours__number}>
            <div>
              <span>{publications?.mesExercice}</span>
            </div>
          </div>

          <AppButton className={`${s.button} `}>{"Mes exercices"}</AppButton>
        </div>
      </div>
    </div>
  );
};

export default ContributionsProfileTeacherCard;
