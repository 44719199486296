import {
    Form,
    message
} from "antd";
import { FC, useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//!STYLES
import TimeZoneList from "../components/TimeZoneList";
import st from "./assets/register.module.css";
/* import TeacherRegistrationCalendar from "../components/RegistrationCalendar"; */
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import { StatusCode } from "common/enums";
import AppButton from "components/libs/button";
import * as appRoutes from "config/routes.config";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateUser } from "store/auth/authSlice";
import MainTeacherCalendar from "../calendarTeacher";


type Props = {
    stepSevenData: any;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
};

const StepEight: FC<Props> = ({ stepSevenData, setCurrent }) => {
    //!HOOKS
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const [formInfos] = Form.useForm();

    //!STATES
    const [isLoading, setIsLoading] = useState<boolean>(false);

    //!CONSTANTS
    const validateMessages = {
        required: t("auth.error.required"),
        types: {
            email: t("auth.error.emailInvalid"),
        },
    };

    //!STATES JOFREY
    const [stepTwoData, setStepTwoData] = useState<any>({});

    //!FUNCTIONS JOFREY
    const endRegistation = async (values: any) => {
        setIsLoading(true);
        const finalData = {
            fuseauHorraire: values.fuseauHorraire,
            step: "completed",
        };

        try {
            const response = await AUTH_SERVICES.updateTeacher(user?._id, {
                ...finalData,
            });
            if (response?.statusCode === StatusCode.OK) {
                message.success({
                    content: "Modification effectuée ",
                });
                dispatch(
                    updateUser({ ...user, fuseauHorraire: values.fuseauHorraire, step: "completed" })
                );

            }
            else {
                message.warning({
                    content: "une erreur est survenue",
                });
            }
        } catch (error: any) {
            let content = "";
            for (
                let index = 0;
                index < error?.response?.data?.message?.length;
                index++
            ) {
                content = error?.response?.data?.message[index] + ", " + content;
            }
            message.error({
                content: content,
            });
        } finally {
            setIsLoading(false);
            navigate(appRoutes.TEACHER_ACTIVITIES);
        }
    };

    const passUpdateProfile = async () => {
        setIsLoading(true);
        const finalData = {
            step: "completed",
        };
        try {
            const response = await AUTH_SERVICES.updateTeacher(user?._id, {
                ...finalData,
            });
            if (response?.statusCode === StatusCode.OK) {
                message.success({
                    content: "Modification effectuée ",
                });
                dispatch(
                    updateUser({
                        ...user,
                        step: "completed",
                    })
                );

            }
            else {
                message.warning({
                    content: "une erreur est survenue",
                });
            }
        } catch (error: any) {
            let content = "";
            for (
                let index = 0;
                index < error?.response?.data?.message?.length;
                index++
            ) {
                content = error?.response?.data?.message[index] + ", " + content;
            }
            message.error({
                content: content,
            });
        } finally {
            setIsLoading(false);
            navigate(appRoutes.TEACHER_ACTIVITIES);
        }
    }

    //!EFFECTS
    useEffect(() => {
        if (user) {
            if (user?.fuseauHorraire !== '') {
                formInfos.setFieldsValue({
                    fuseauHorraire: user?.fuseauHorraire,
                });
            }
        }
    }, [user, formInfos]);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [teacherData, setTeacherData] = useState<any>();

    const getTeacherData = async (teacherId: string) => {
        try {
            const response = await AUTH_SERVICES.getTeacherData(user?._id, teacherId);
            setTeacherData(response?.data);
        } catch (error: any) { }
    };

    useEffect(() => {
        getTeacherData(user?._id);
    }, []);

    return (
        <div style={{ display: "flex", flexDirection: "column", margin: "0px auto", width: "100%" }}>
            <Form
                validateTrigger={["onFinish"]}
                name="registerTeacher"
                autoComplete="off"
                form={formInfos}
                validateMessages={validateMessages}
                onFinish={endRegistation}
            >

                <div className={st.calendarBoxTitle}>Votre fuseau horaire</div>
                <div className={st.calendareBoxMessage}>Il est essentiel d’être sur le bon fuseau horaire pour coordonner les leçons avec les élèves à l’international</div>

                <div className={st.calendarBoxSubtitle}>
                    Choisissez votre fuseau horaire
                    <div className={st.calendarTimezone}>
                        <TimeZoneList />
                        <div className={st.fillLaterButton}>
                            <AppButton loading={isLoading} onClick={passUpdateProfile}><a className={st.nextButton}>REMPLIR PLUS TARD</a></AppButton>
                        </div>
                    </div>
                </div>

                {/* <div className={st.calendarBox}><TeacherRegistrationCalendar /></div> */}
                <div className={st.calendarBox}><MainTeacherCalendar currentTeacher={teacherData} /></div>

                <div className={st.rowButtons}>
                    <div className={st.rowButtonChild}>
                        <AppButton onClick={() => setCurrent((n) => n - 1)}><a className={st.nextButton}>RETOUR</a></AppButton>
                    </div>
                    <div className={st.rowButtonChild}>
                        <Form.Item style={{ margin: 0, padding: 0 }}>
                            <AppButton loading={isLoading} htmlTypeSubmit={true}><a className={st.nextButton}>TERMINER L’INSCRIPTION</a></AppButton>
                        </Form.Item>
                    </div>
                </div>

            </Form>
        </div>
    );
};

export default StepEight;
