import { Button, Popover } from 'antd';
import * as FAQ_SERVICES from 'api/services/faq/faq.services';
import { FC, useEffect, useState } from 'react';

type Props = {
    id: any;
}

const HelpPopover: FC<Props> = ({ id }) => {

    const [content, setContent] = useState<string>('Aide et assistance');
    const [loading, setLoading] = useState<boolean>(false);

    // Function to fetch data from the API
    const fetchContent = async () => {
        try {
            setLoading(true);
            const response = await FAQ_SERVICES.fetchHelp(id);
            setContent(response?.data?.content);
        } catch (error) {
            setContent('Erreur chargement du contenu');
        } finally {
            setLoading(false);
        }
    };

    // Popover content
    const popoverContent = (
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
    );

    useEffect(() => {
        fetchContent();
    }, [id]);

    return (
        <Popover
            content={popoverContent}
            title="Aide et assistance"
            trigger="click"
            onVisibleChange={(visible) => {
                if (visible && !content) {
                    fetchContent();
                }
            }}
            overlayStyle={{ width: 400 }}
            placement="top"
        >
            <Button type="primary" style={{ borderRadius: "50%", height: "30px", width: "30px", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", padding: "0px" }}>?</Button>
        </Popover>
    );
};

export default HelpPopover;
