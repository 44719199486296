import { Checkbox } from "antd";
import FadeInOnScroll from "components/FadeIn";
import FadeInUpOnScroll from "components/FadeInUp";
import { useState } from "react";
import elipse_teachers from "../../../../assets/images/app/home/elipse_teachers.svg";
import elipse_valeur from "../../../../assets/images/app/home/elipse_valeur.svg";
import ellipse_mission from "../../../../assets/images/app/home/ellipse_mission.svg";
import trace_contact from "../../../../assets/images/app/home/trace_contact.svg";
import contactus_organisme from "../../../../assets/images/app/organisme/contactus_organisme.png";
import st from "../../../app/common.module.css";
import s from "./style.module.css";

const ContactUs = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <div className={s.mainContainer}>
            <div className={s.contactContainer}>
                <FadeInUpOnScroll>
                    <h1 className={s.contactTitle}>Contactez-nous</h1>
                </FadeInUpOnScroll>
                <img src={ellipse_mission} className={s.ellipseTitle}></img>
                <FadeInOnScroll>
                    <p className={s.contactSubtitle}>Nous sommes à l’écoute!</p>
                </FadeInOnScroll>
                <div className={s.contactBox}>
                    <div className={s.contactMain}>
                        <div className={s.formBox}>
                            <form style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                {!isMobile && <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                    <div style={{ textAlign: "left" }}>
                                        <label htmlFor="firstname">Nom *</label>
                                        <input className={s.nameInput} type="text" id="firstname" name="firstname" required value=""></input>
                                    </div>
                                    <div style={{ textAlign: "left", marginLeft: "44px" }}>
                                        <label htmlFor="lastname">Prénom *</label>
                                        <input className={s.lastnameInput} type="text" id="lastname" name="lastname" required value=""></input>
                                    </div>
                                </div>}

                                {isMobile && <div className={s.inputDiv}>
                                    <label htmlFor="firstname">Nom *</label>
                                    <input className={s.nameInput} type="text" id="firstname" name="firstname" required value=""></input>
                                </div>}

                                {isMobile && <div className={s.inputDiv}>
                                    <label htmlFor="lastname">Prénom *</label>
                                    <input className={s.lastnameInput} type="text" id="lastname" name="lastname" required value=""></input>
                                </div>}

                                <div className={s.inputDiv}>
                                    <label htmlFor="email">Adresse email *</label>
                                    <input className={s.emailInput} type="email" id="email" name="email" required value=""></input>
                                </div>
                                <div className={s.inputDiv}>
                                    <label htmlFor="phone">Numéro de téléphone</label>
                                    <input className={s.phoneInput} type="tel" id="phone" name="phone" value=""></input>
                                </div>
                                <div className={s.inputDiv} style={{ color: "rgb(47, 47, 47)" }}>
                                    <label htmlFor="message">Message</label>
                                    <textarea className={s.textareaInput} id="message" name="message" required></textarea>
                                </div>
                                <div className={s.checkboxTitle}>
                                    <Checkbox className={s.checkboxStyle}>
                                        {" "}
                                        J’ai lu et j’accepte la politique de confidentialité ainsi que<br />les Conditions Générales.
                                    </Checkbox>
                                </div>
                                <button type="submit" className={st.submitButton}>Envoyer</button>
                            </form>
                        </div>
                        {!isMobile && <img src={elipse_teachers} style={{ height: "20px", position: "relative", left: "12%", top: "50px" }}></img>}
                        {!isMobile && <img src={elipse_valeur} style={{ width: "15px", position: "relative", left: "51%", top: "-30%" }}></img>}
                        {!isMobile && <img src={contactus_organisme} style={{ height: "800px", position: "relative", right: "-4%", top: "31px", zIndex: "1" }}></img>}
                        {!isMobile && <img src={trace_contact} style={{ height: "805px", position: "relative", right: "37%", top: "40%", zIndex: "0" }}></img>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;