import Card from "components/card";
import * as appRoutes from "config/routes.config";
import "katex/dist/katex.min.css";
import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as IconDropDown } from "../../../../assets/images/app/dropDownIcon.svg";
import MATH from "../../../../assets/images/teacher/iconMath.svg";
import CoursICons from "./assets/images/cours.png";
//!STYLES
import {
  Button,
  Col,
  Dropdown,
  Image,
  Menu,
  message,
  Row,
  Space,
  Tooltip,
} from "antd";
import { deleteQuizz } from "api/services/quizz/quizz.services";
import { StatusCode, UserRoles } from "common/enums";
import AppDrawer from "components/appDrawer";
import HelpPopover from "components/help";
import QuizMenu from "components/libs/readyToUse/apercueQuiz/quiz/QuizMenu";
import H2 from "components/libs/subtitle";
import { Link } from "react-router-dom";
import { updateItemsQuizzes } from "store/quizz/quizzSlice";
import { formatDates } from "utils/formats";
import s from "./assets/cardQuizz.module.css";

interface CardCourses {
  quizz?: any;
  className?: string;
}

const CardQuizz: FC<CardCourses> = ({ quizz, className }) => {
  const { user } = useSelector((state) => state.auth);
  const [drawer, setDrawer] = useState<boolean>(false);
  const [currentQuizz, setCurrentQuizz] = useState();
  const dipsatch = useDispatch();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  // !FUNCTIONS
  const deleteQuizzFunction = async (idQuizz: string) => {
    try {
      const response = await deleteQuizz(idQuizz);

      if (response?.statusCode === StatusCode.OK) {
        message.success("Suppression a été bien effectuée");
      } else {
        message.warning("Suppression non effectuée");
      }
    } catch (e: any) {
      message.error("Une erreur a été survenue");
    }
  };
  const isTeacher = user?.roles?.includes(UserRoles.TEACHER);

  const menu = (
    <React.Fragment>
      <Menu className={s.__down}>
        {quizz?.status === "DRAFT" && (
          <React.Fragment>
            {quizz?.teacherId === user?._id ? (
              <React.Fragment>
                {!isMobile && <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_QUIZZ}/${quizz._id}`}
                    key={quizz._id}
                  >
                    Modifier mon quiz
                  </Link>
                </Menu.Item>}

                <Menu.Item>
                  <Link
                    to={"#"}
                    onClick={() => {
                      setCurrentQuizz(quizz?.questions);
                      setDrawer(!drawer);
                    }}
                  >
                    Vue d'ensemble
                  </Link>
                </Menu.Item>

                {!isMobile && <Menu.Item>
                  <Link
                    to={"#"}
                    onClick={() => {
                      dipsatch(updateItemsQuizzes(quizz?._id));
                      deleteQuizzFunction(quizz?._id);
                    }}
                  >
                    Supprimer
                  </Link>
                </Menu.Item>}
                {user.roles.includes("admin") && (
                  <Menu.Item>
                    <Link
                      to={`${appRoutes.TEACHER_QUIZZ}/${quizz._id}`}
                      key={quizz._id}
                    >
                      Modifier
                    </Link>
                  </Menu.Item>
                )}
              </React.Fragment>
            ) : (
              <>
                <Menu.Item>
                  <Link to={"#"} onClick={() => setDrawer(!drawer)}>
                    Vue d'ensemble
                  </Link>
                </Menu.Item>

                {user.roles.includes("admin") && (
                  <Menu.Item>
                    <Link
                      to={`${appRoutes.TEACHER_QUIZZ}/${quizz._id}`}
                      key={quizz._id}
                    >
                      Modifier
                    </Link>
                  </Menu.Item>
                )}
              </>
            )}
          </React.Fragment>
        )}

        {quizz?.status === "PENDING" && (
          <React.Fragment>
            <Menu.Item>
              <Link
                to={"#"}
                onClick={() => {
                  setCurrentQuizz(quizz?.questions);
                  setDrawer(!drawer);
                }}
              >
                Vue d'ensemble
              </Link>
            </Menu.Item>

            {user.roles.includes("admin") && (
              <Menu.Item>
                <Link
                  to={`${appRoutes.TEACHER_QUIZZ}/${quizz._id}`}
                  key={quizz._id}
                >
                  Modifier
                </Link>
              </Menu.Item>
            )}
          </React.Fragment>
        )}

        {quizz?.status === "REJECTED" && (
          <React.Fragment>
            <Menu.Item>
              <Link
                to={"#"}
                onClick={() => {
                  setCurrentQuizz(quizz?.questions);
                  setDrawer(!drawer);
                }}
              >
                Vue d'ensemble
              </Link>
            </Menu.Item>

            {user.roles.includes("admin") && (
              <Menu.Item>
                <Link
                  to={`${appRoutes.TEACHER_QUIZZ}/${quizz._id}`}
                  key={quizz._id}
                >
                  Modifier
                </Link>
              </Menu.Item>
            )}
          </React.Fragment>
        )}

        {quizz?.status === "PUBLISHED" && (
          <React.Fragment>
            {quizz?.teacherId === user?._id ? (
              <>
                <Menu.Item>
                  <Tooltip
                    placement="bottom"
                    title={" Vue d'ensemble mon quiz"}
                  >
                    <Link
                      to={"#"}
                      onClick={() => {
                        setDrawer(!drawer);
                        setCurrentQuizz(quizz?.questions);
                      }}
                    >
                      Vue d'ensemble mon quiz
                    </Link>
                  </Tooltip>
                </Menu.Item>
                {!isMobile && <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_QUIZZ}/${quizz._id}/edit/propositions`}
                  >
                    Ameliorer mon quiz
                  </Link>
                </Menu.Item>}
                {user.roles.includes("admin") && (
                  <Menu.Item>
                    <Link
                      to={`${appRoutes.TEACHER_QUIZZ}/${quizz._id}`}
                      key={quizz._id}
                    >
                      Modifier
                    </Link>
                  </Menu.Item>
                )}

                {user?.isActive && user?.step === "completed" && !isMobile && <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_QUIZZ}/${quizz._id}/duplicate`}
                  >
                    Dupliquer
                  </Link>
                </Menu.Item>}
              </>
            ) : (
              <>
                <Menu.Item>
                  <Link
                    to={"#"}
                    onClick={() => {
                      setDrawer(!drawer);
                      setCurrentQuizz(quizz?.questions);
                    }}
                  >
                    Vue d'ensemble
                  </Link>
                </Menu.Item>
                {user?.isActive && user?.step === "completed" && !isMobile && <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_QUIZZ}/${quizz._id}/edit/propositions`}
                  >
                    Ameliorer
                  </Link>
                </Menu.Item>}
                {user?.isActive && user?.step === "completed" && !isMobile && <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_QUIZZ}/${quizz._id}/duplicate`}
                  >
                    Dupliquer
                  </Link>
                </Menu.Item>}
                {user.roles.includes("admin") && (
                  <Menu.Item>
                    <Link
                      to={`${appRoutes.TEACHER_QUIZZ}/${quizz._id}`}
                      key={quizz._id}
                    >
                      Modifier
                    </Link>
                  </Menu.Item>
                )}
              </>
            )}
          </React.Fragment>
        )}
      </Menu>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Card className={s.quizCard}>
        <div className={s.content}>
          <div
            className={s.__badge}
            style={{
              background: `

              ${quizz?.status === "PUBLISHED"
                  ? "#80B1B1"
                  : quizz?.status === "DRAFT"
                    ? "#064205"
                    : quizz?.status === "PENDING"
                      ? "#ea9610"
                      : quizz?.status === "REJECTED" && "#FF0000"
                }
              `,
            }}
          >
            <span>
              {quizz?.status === "DRAFT" && "Brouillon"}
              {quizz?.status === "PENDING" && "En cours de validation"}
              {quizz?.status === "PUBLISHED" && "Publié"}
              {quizz?.status === "REJECTED" && "rejeté"}
            </span>
          </div>
          <div className={s.__top}>
            <div>
              <span>Update : {formatDates(quizz?.updatedAt)}</span>
            </div>
            <div className={s.matiers}>
              <div className={s.left}>
                <Row style={{ alignItems: "center" }}>
                  <Col>
                    <Image
                      preview={false}
                      src={MATH}
                      className={s.__img}
                      width={18}
                    />
                  </Col>
                  <Col>
                    <span>{quizz?.course?.title.substring(0, 4)}</span>
                  </Col>
                </Row>
              </div>
              <div className={s.center}>
                <span>|</span>
              </div>
              <div className={s.right}>
                <span>{quizz?.course?.level?.title}</span>
              </div>
            </div>
            <Tooltip placement="top" title={quizz?.title}>
              <h3>{quizz?.title}</h3>
            </Tooltip>
            <h4 className={s.__chapter}>
              <img
                src={CoursICons}
                alt=""
                width={20}
                style={{
                  position: "relative",
                  top: "-1px",
                }}
              />
              {quizz?.chapterId?.title}
            </h4>
          </div>

          {isTeacher && <div className={s.__bottom} style={{ marginTop: "10px" }}>
            <Dropdown
              className={`${s.dropdown}`}
              overlay={menu}
              placement="bottomCenter"
            >
              <Button>
                <Space>
                  Contribuer
                  <IconDropDown
                    style={{
                      position: "relative",
                      left: "15px",
                    }}
                  />
                </Space>
              </Button>
            </Dropdown>
          </div>}
          <div style={{ position: "relative", top: "-28px", left: "35%" }}><HelpPopover id={"mon_contenu_contribuer"} /></div>
        </div>
      </Card>

      <AppDrawer
        title={
          <Tooltip title={quizz?.title} placement="bottom">
            <H2>{quizz?.title?.substring(0, 60)}</H2>
          </Tooltip>
        }
        width={"70%"}
        onClose={() => setDrawer(!drawer)}
        visible={drawer}
      >
        <QuizMenu data={currentQuizz}></QuizMenu>
      </AppDrawer>
    </React.Fragment>
  );
};

export default CardQuizz;
