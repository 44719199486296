import Breadcrumbs from "components/breadcrumbs";
import React, { useState } from "react";
import AppScreen from "../ScreenWithSidebar";
import MainContent from "./components/mainContent";
import TeacherInfos from "./components/profileInfo";
import TeacherEvents from "./components/teacherEvents";
import s from "./style.module.css";
import { useSelector } from "react-redux";

interface Props {}

const TeacherActivities = (props: Props) => {
  const auth = useSelector((state) => state.auth);
  const [postsCount, setPostsCount] = useState(auth?.user?.postsNum ? auth?.user?.postsNum : 0);
  const [docsCount, setDocsCount] = useState(auth?.user?.docsNum ? auth?.user?.docsNum : 0);

  const handleAddPost = () => {
    setPostsCount(postsCount + 1);
  };

  const handleAddDocs = (numToAdd:any) => {
    setDocsCount(docsCount + numToAdd);
  };

  return (
    <React.Fragment>
      <Breadcrumbs isAds />
      <AppScreen>
        <div className={s.flex}>
          <div className={s.left}>
            <TeacherInfos postsCount={postsCount} docsCount={docsCount} />
          </div>
          <div className={s.main}>
            <MainContent onAddPost={handleAddPost} handleAddDocs={handleAddDocs} />
          </div>
          <div className={s.right}>
            <TeacherEvents />
          </div>
        </div>
      </AppScreen>
    </React.Fragment>
  );
};

export default TeacherActivities;
