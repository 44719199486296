import { FC, useEffect, useReducer, useState } from "react";

//!ASSETS
import AboutIcon from "assets/images/app/about.png";
import AvisIcon from "assets/images/app/avis.png";
import CoursIcon from "assets/images/app/contenu.svg";

//!STYLES
import s from "./assets/style.module.css";

//!COMPONENTS
import { Affix } from "antd";
import { useSelector } from "react-redux";
import AboutProfileTeacherCard from "../aboutCard";
import AvisProfileTeacher from "../avis";
import ContributionsProfileTeacherCard from "../contributions";
import PublicationsProfileTeacherCard from "../publications";

enum Tabs {
  ACTIVE_ABOUT = "ACTIVE_ABOUT",
  ACTIVE_PUBLICATIONS = "ACTIVE_PUBLICATIONS",
  ACTIVE_CONTRIBUTIONS = "ACTIVE_CONTRIBUTIONS",
  ACTIVE_AVIS = "ACTIVE_AVIS",
}

interface TabsState {
  about: boolean;
  publications: boolean;
  contributions: boolean;
  avis: boolean;
}

const initialState = {
  about: true,
  publications: false,
  contributions: false,
  avis: false,
};

function reducer(state: TabsState, action: any) {
  switch (action.type) {
    case Tabs.ACTIVE_ABOUT:
      return {
        ...state,
        about: true,
        publications: false,
        avis: false,
        contributions: false,
      };
    case Tabs.ACTIVE_PUBLICATIONS:
      return {
        ...state,
        publications: true,
        about: false,
        avis: false,
        contributions: false,
      };
    case Tabs.ACTIVE_CONTRIBUTIONS:
      return {
        ...state,
        contributions: true,
        publications: false,
        about: false,
        avis: false,
      };
    case Tabs.ACTIVE_AVIS:
      return {
        ...state,
        avis: true,
        publications: false,
        about: false,
        contributions: false,
      };

    default:
      throw new Error();
  }
}

type Props = {
  teacher: any;
};

const AboutProfileTeacher: FC<Props> = ({ teacher }) => {

  const { seeAvis } = useSelector((state) => state.auth);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [top, setTop] = useState<number>(10);

  useEffect(() => {
    if (seeAvis) {
      dispatch({ type: Tabs.ACTIVE_AVIS })
      const element = document.getElementById("avis_");
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        setTimeout(() => {
          window.scrollBy({
            top: 750,
            behavior: 'smooth',
          });
        }, 300);
      }
    }
  }, [seeAvis]);

  return (
    <div className={s.about}>
      <Affix offsetTop={top}>
        <div className={s.tabs}>
          <a href="#about_" className={s.redirect}>
            <div
              className={`${s.tab} ${state.about && s.active} `}
              onClick={() => dispatch({ type: Tabs.ACTIVE_ABOUT })}
            >
              <img src={AboutIcon} alt="" /> <span>À Propos</span>
            </div>
          </a>

          <a href="#publications_" className={s.redirect}>
            <div
              className={`${s.tab} ${state.publications && s.active} `}
              onClick={() => dispatch({ type: Tabs.ACTIVE_PUBLICATIONS })}
            >
              <img src={CoursIcon} alt="" /> <span>Publications</span>
            </div>
          </a>

          <a href="#contributions_" className={s.redirect}>
            <div
              className={`${s.tab} ${state.contributions && s.active} `}
              onClick={() => dispatch({ type: Tabs.ACTIVE_CONTRIBUTIONS })}
            >
              <img src={CoursIcon} alt="" /> <span>Contributions</span>
            </div>
          </a>

          <a href="#avis_" className={s.redirect}>
            <div
              className={`${s.tab} ${state.avis && s.active} `}
              onClick={() => dispatch({ type: Tabs.ACTIVE_AVIS })}
            >
              <img src={AvisIcon} alt="" /> <span>Avis</span>
            </div>
          </a>
        </div>
      </Affix>

      {/* {state.about &&} */}
      <div id="about_">
        <AboutProfileTeacherCard teacher={teacher?.prof} />
      </div>

      {/* {state.publications && (

      )} */}

      <div id="publications_">
        <PublicationsProfileTeacherCard publications={teacher?.publications} />
      </div>

      <div id="contributions_">
        <ContributionsProfileTeacherCard publications={teacher?.publications} />
      </div>

      {/* {state.avis && } */}

      <div id="avis_">
        <AvisProfileTeacher avis={teacher} />
      </div>
    </div>
  );
};

export default AboutProfileTeacher;
