/* eslint-disable jsx-a11y/anchor-is-valid */
import { Drawer, message } from "antd";
import { API_BASE_URL_RETRAITS } from "api/services/paiement/paiement.api.routes";
import { ReactComponent as BackBlackIcon } from "assets/images/app/backBlack.svg";
import axios from "axios";
import { StatusCode } from "common/enums";
import H2 from "components/libs/subtitle";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closePorteFeuilleDrawer__ } from "store/auth/authSlice";
import { CustomTab } from "./customTab/CustomTab";
export const InformationsPaiement: React.FC = () => {

  const dispatch = useDispatch();
  const { porteFeuilleDrawerOpen } = useSelector((state) => state.auth);
  const visibleHandler = () => dispatch(closePorteFeuilleDrawer__());
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [alreadyAsking4Money, setAlreadyAsking4Money] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  useEffect(() => {
    const retrieveFunction = async () => {
      try {
        let response = await axios.get(API_BASE_URL_RETRAITS);
        if (response.status === StatusCode.OK) {
          if (response?.data?.data.length === 0) setAlreadyAsking4Money(true);
        }
      } catch (error) {
        message.error("Une erreur inconnue s'est produite.");
      }
    };
    if (isLoggedIn) {
      retrieveFunction();
    }
  }, [isLoggedIn, porteFeuilleDrawerOpen]);

  return (
    <Drawer
      placement="right"
      width={isMobile ? "100%" : "60%"}
      onClose={visibleHandler}
      visible={porteFeuilleDrawerOpen}
      closable={true}
      title={<H2>PORTEFEUILLE</H2>}
      headerStyle={{ border: "none" }}
      closeIcon={
        <BackBlackIcon
          style={{
            width: "25px",
          }}
        />
      }
    >
      <div
        style={{
          display: "flex",
          padding: "0 50px",
          width: "100%",
        }}
      >
        <CustomTab alreadyAsking4Money={alreadyAsking4Money} />
      </div>
    </Drawer>
  );
};
