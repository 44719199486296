import { Skeleton } from "antd";
import socket from "config/api.socket";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetPostsQuery } from "store/services/teacher/activities.services";
import CardActivities from "./components/card";
import MainContentTop from "./components/mainContentTop";
import s from "./style.module.css";

interface Props { }

interface MainContentProps {
  onAddPost: () => void;
  handleAddDocs: (param: any) => void;
}

const MainContent: React.FC<MainContentProps> = ({ onAddPost, handleAddDocs }) => {
  const { user } = useSelector((state) => state.auth);
  const { data, isLoading, isError, refetch } = useGetPostsQuery(user?._id);
  const [allData, setAllData] = useState<any[]>([]);

  useEffect(() => {
    if (data?.data?.results) {
      setAllData(data.data.results);
    }
  }, [data, refetch]);

  useEffect(() => {
    socket?.on("connect", () => {
      console.log("Socket connected!");
    });

    socket?.on("newPost", (postData: any) => {
      //setAllData((prevData: any[]) => [...prevData, postData.post.post]); 
      refetch();
    });

    return () => {
      socket?.off("newPost");
    };
  }, [socket, data, refetch]);

  return (
    <div className={s.mainContent}>
      {user?.roles.includes("teacher") && user?.isActive && user?.step === "completed" && <MainContentTop onAddPostTop={onAddPost} handleAddDocsTop={handleAddDocs} />}

      {isLoading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <>
          {allData?.map((info: any, index: number) => (
            <CardActivities key={info._id} info={info} />
          ))}
        </>
      )}
    </div>
  );
};

export default MainContent;
