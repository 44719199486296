import React, { FC, Fragment, useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  Avatar,
  Button,
  Col,
  Form,
  message,
  Modal,
  Rate,
  Result,
  Row,
} from "antd";
import { SendOutlined, UserOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import Input from "antd/lib/input/Input";

import { useParams } from "react-router-dom";
import { useSendAvisMutation } from "store/services/teacher/profileTeacher";
import { useSelector } from "react-redux";

import CardAvis from "../cardAvis";

//!ASSETS
import Teacher from "assets/images/teacher/teacher.svg";
import StartIcon from "assets/images/students/teacherStart.svg";

//!STYLES
import s from "./assets/style.module.css";
import { badRequest } from "messages";
import AppResult from "components/appResults";

type Props = {
  avis: any;
};

const AvisProfileTeacher: FC<Props> = ({ avis }) => {
  //!HOOKS
  const params = useParams<"id">();
  const [sendAvis, { data, isLoading, isError, isSuccess }] =
    useSendAvisMutation();
  const { user } = useSelector((state) => state.auth);
  const [form] = Form.useForm();

  //!STATES
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onSubmitAvis = (values: any) => {
    const finalData = {
      ...values,
      studId: user?._id,
      profId: params.id!,
    };
    try {
      sendAvis(finalData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isError) {
      message.error(badRequest.error);
    }

    if (isSuccess) {
      form.resetFields();
      handleCancel();
      message.success("Votre avis a été ajouter avec succès");
    }
  }, [isError, isSuccess]);

  return (
    <React.Fragment>
      <div className={s.card}>
        <Row
          justify="space-between"
          style={{ marginBottom: "1em" }}
          align="middle"
        >
          <Col>
            <h3>Avis des etudiants</h3>
          </Col>

          <Col>
            <Row gutter={8}>
              <Col>
                {!user || !user.roles.includes("teacher") && (
                  <Fragment>
                    {!avis?.alreadyGivedAvis && (
                      <Button
                        shape="round"
                        type="primary"
                        style={{ border: "unset" }}
                        onClick={showModal}
                        className={s.btn}
                      >
                        Donner un avis
                      </Button>
                    )}
                  </Fragment>
                )}
              </Col>
              <Col>
                <div className={s.tagAvis}>
                  <img
                    src={StartIcon}
                    alt=""
                    width={17}
                    style={{
                      position: "relative",
                      top: "-2px",
                      marginRight: "5px",
                    }}
                  />

                  <span>
                    <strong>{avis?.rate || 0} </strong>
                  </span>

                  <span>{`( ${avis?.rateNum || 0} avis)`}</span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <div className={s.content}>
          {avis?.avis?.length === 0 ? (
            <AppResult status={"info"} title="Aucun avis disponible : )" />
          ) : (
            <>
              {avis?.avis?.map((av: any) => (
                <CardAvis av={av} />
              ))}
            </>
          )}
        </div>
      </div>

      <Modal
        title={false}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        centered
        bodyStyle={{ padding: "2em" }}
        width={"50vw"}
      >
        <Row justify="center" style={{ marginBottom: "1em" }}>
          <Col>
            <Avatar size={64} src={avis?.profile || Teacher} />
          </Col>
        </Row>

        <Form layout="vertical" onFinish={onSubmitAvis} form={form}>
          <Row justify="center">
            <Col>
              <Form.Item
                name={"rate"}
                rules={[{ required: true, message: "Ce champs est requis" }]}
              >
                <Rate allowHalf defaultValue={0} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name={"content"}
            label="Mon avis"
            rules={[
              { required: true, message: "Ce champs est requis" },
              {
                type: "string",
                min: 6,
                message: "minimum de 6 caractères : )",
              },
            ]}
          >
            <div style={{ border: "1px solid #ccc", borderRadius: "5px" }}>
              <TextArea rows={4} bordered={false} />
            </div>
          </Form.Item>

          <Row justify="center">
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ textTransform: "uppercase", border: "unset" }}
                  loading={isLoading}
                  shape="round"
                  icon={<SendOutlined />}
                >
                  Envoyer mon avis
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default AvisProfileTeacher;
