import React, { FC, useCallback, useEffect, useState } from "react";
import { Col, Image, Row } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import Math from "../../../../assets/images/teacher/iconMath.svg";
import { findTopicByIdStudent } from "api/services/topics/topics.services";

//!ASSETS
import s from "./assets/style.module.css";

//IMAGES
import MATH from "../../../../assets/images/app/iconMatiers/Mathematiques.png";
import PHILO from "../../../../assets/images/app/iconMatiers/Philosophie.png";
import PHYSIQUE from "../../../../assets/images/app/iconMatiers/Physique-chimie.png";
import SVT from "../../../../assets/images/app/iconMatiers/SVT.png";
import ANGLAIS from "../../../../assets/images/app/iconMatiers/Anglais.png";
import HISTOIREGEO from "../../../../assets/images/app/iconMatiers/Histoire-geo.png";
import FRANCAIS from "../../../../assets/images/app/iconMatiers/Francais.png";

import { useDispatch, useSelector } from "react-redux";
import { TypeMatiers } from "common/enums";

type Props = {
  coursId?: string;
};

const HeaderComponents: FC<Props> = ({ coursId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams<"id">();
  const { idCours } = useSelector((state: any) => state.courses);
  const [topic, setTopic] = useState<any>({});
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const getTopic = JSON.parse(localStorage.getItem("currentTopic") || "{}");
    setTopic(getTopic);
  }, []);

  return (
    <div className={s.headerComponents}>
      <Row
        justify="center"
        align="middle"
        gutter={4}
        className={s.__content__matiere}
      >
        <Col>
          {" "}
          <Image
            src={
              topic?.description == TypeMatiers.mathematique
                ? MATH
                : topic?.description == TypeMatiers.SVT
                  ? SVT
                  : topic?.description == TypeMatiers.PhysiqueChimie
                    ? PHYSIQUE
                    : topic?.description == TypeMatiers.Philosophie
                      ? PHILO
                      : topic?.description == TypeMatiers.HistoireGeo
                        ? HISTOIREGEO
                        : topic?.description == TypeMatiers.Francais
                          ? FRANCAIS
                          : topic?.description == TypeMatiers.Anglais
                            ? ANGLAIS
                            : ""
            }
            preview={false}
          />
        </Col>
        <Col>{topic?.title}</Col>
        <Col>|</Col>
        <Col>{topic?.level?.title}</Col>
      </Row>
    </div>
  );
};

export default HeaderComponents;
