import React, { FC } from "react";
import { Avatar, Tooltip } from "antd";
import { AntDesignOutlined, UserOutlined } from "@ant-design/icons";

//!STYLES
import s from "./style.module.css";

type Props = {
  user: any;
};

const AvatarGroup: FC<Props> = ({ user }) => {
  return (
    <Avatar.Group
      maxCount={0}
      maxStyle={{
        color: "#f56a00",
        backgroundColor: "#fde3cf",
      }}
      className={s.avatar}
      size={"small"}
    >
      <Tooltip
        title={
          <span style={{ textTransform: "capitalize" }}>
            {user.firstname} {user.lastname}
          </span>
        }
        placement="top"
      >
        <Avatar size={"small"} className={s.avatar} src={user?.profile} />
      </Tooltip>
      {/* <Avatar style={{ backgroundColor: "#f56a00" }}>K</Avatar> */}

      {/* <Tooltip title="Ant User" placement="top">
        <Avatar
          style={{
            backgroundColor: "#87d068",
          }}
          icon={<UserOutlined />}
          size={"small"}
        />
      </Tooltip> */}
    </Avatar.Group>
  );
};

export default AvatarGroup;
