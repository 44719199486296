import { Form, Input } from "antd";
import AppButton from "components/libs/button";
import React, { FC, useEffect, useState } from "react";

//!REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  useGetAllRoomsQuery,
  useSendMessageMutation,
} from "store/services/chat";

//!STYLE
import s from "./style/message.module.css";

type Props = {
  conversation: any;
  setConversation: React.Dispatch<any>;
  setIsTyping: React.Dispatch<any>;
};

const Message: FC<Props> = ({ conversation, setConversation, setIsTyping }) => {
  //!HOOKS
  const dispatch = useDispatch();
  const {
    talkId,
    idReceiver,
    socket,
    idSender,
    conversation: conversationStore,
  } = useSelector((state) => state.appChat);
  const { user } = useSelector((state) => state.auth);
  const [sendMessage, { data, isLoading, isError }] = useSendMessageMutation();

  const { refetch } = useGetAllRoomsQuery(user?._id);

  const [lastMessage, setLastMessage] = useState([]);
  const [form] = Form.useForm();

  const onSendMessage = async (values?: any) => {
    if (values) {
      const bodySendMessage = {
        talkId: talkId,
        sender: conversationStore?.receiverIdC
          ? user?._id
          : user?._id == idSender
          ? idSender
          : idReceiver,
        receiver: user?._id != idSender ? idSender : idReceiver,
        message: values.msg,
        flag: conversationStore?.receiverIdC ? "GROUP_FLAG" : "SINGLE_FLAG",
        instaprof: conversationStore?.instaProf ? "find" : "",
      };

      const bodySendMessageGroupe: any = {
        ...bodySendMessage,
        receiver: conversationStore?.receiverIdC?._id,
      };

      // delete bodySendMessageGroupe?.receiver;

      const checkBody = conversationStore?.receiverIdC
        ? bodySendMessageGroupe
        : bodySendMessage;

      const idUserGetReceiver = user?._id != idSender ? idSender : idReceiver;

      let tempMsg = {
        senderId: user,
        receiverId: {
          _id: idUserGetReceiver,
        },
        message: values.msg,
        seen: true,
      };

      socket?.connect();
      await socket.emit("sendMessage", checkBody);

      setConversation((res: any) => {
        return [...res, tempMsg];
      });

      form.resetFields();

      // refetch();
    }
  };

  const handleTyping = async () => {
    socket?.connect();
    await socket.emit("isTyping", talkId);
  };

  useEffect(() => {
    socket?.on("newMessage", async (msgResponse: any) => {
      console.log(msgResponse, "msgResponse");
      setConversation((res: any) => {
        return [...res, msgResponse?.data];
      });
      refetch();
      setIsTyping(false);
    });
  }, [socket]);

  useEffect(() => {
    socket?.on("userTyping", async (msgResponse: any) => {
      setIsTyping(true);
    });
  }, [socket]);

  return (
    <Form onFinish={onSendMessage} form={form}>
      <div className={s.message}>
        <div className={s.textArea}>
          <Form.Item
            name={"msg"}
            style={{ margin: 0, padding: 0 }}
            rules={[
              { required: true, message: "Merci de renseigner un message" },
            ]}
          >
            <Input.TextArea
              bordered={false}
              rows={2}
              onChange={handleTyping}
              placeholder="Rédige ton message ici"
            />
          </Form.Item>
        </div>
        <div>
          <AppButton htmlTypeSubmit={true} className={s.button}>
            Envoyer
          </AppButton>
        </div>
      </div>
    </Form>
  );
};

export default Message;
