import React from "react";
import { Image } from "antd";
import { Link, useLocation } from "react-router-dom";
import * as appRoutes from "config/routes.config";

//!IMAGES
import Cours from "assets/images/teacher/cours.svg";
import Quiz from "assets/images/teacher/quiz.svg";
import Exo from "assets/images/teacher/exo.svg";

//!STYLE
import s from "./style.module.css";

const HeaderTabContent = () => {
  //!HOOKS
  const location = useLocation();
  return (
    <div className={s.tabs}>
      <Link to={appRoutes.TEACHER_COURSES}>
        <div
          className={`${s.tab} ${
            location.pathname === appRoutes.TEACHER_COURSES && s.__tab__active
          }`}
        >
          <Image preview={false} width={65} src={Cours} alt="teacher avatar" />
        </div>
      </Link>
      <Link to={appRoutes.TEACHER_QUIZZ}>
        <div
          className={`${s.tab} ${
            location.pathname === appRoutes.TEACHER_QUIZZ && s.__tab__active
          }`}
        >
          <Image preview={false} width={65} src={Quiz} alt="teacher avatar" />
        </div>
      </Link>
      <Link to={appRoutes.TEACHER_EXERCICES}>
        <div
          className={`${s.tab} ${
            location.pathname === appRoutes.TEACHER_EXERCICES && s.__tab__active
          }`}
        >
          <Image preview={false} width={30} src={Exo} alt="teacher avatar" />
        </div>
      </Link>
    </div>
  );
};

export default HeaderTabContent;
