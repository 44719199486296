import { Avatar, Button, Popconfirm, Row, Skeleton, Typography } from "antd";
import AppDrawer from "components/appDrawer";
import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import starCoins from "../../../../assets/images/app/star-coins.png";
import TEACHER from "../../../../assets/images/teacher/teacher.svg";

//!STYLES
import Col from "antd/es/grid/col";
import { findReservationStudent } from "api/services/studentReservation/studentReservation.services";
import { API_RESSOURCES } from "api/services/teacher/teacher.routes";
import axios from "axios";
import { StatusCode } from "common/enums";
import { AppNotification } from "components/appNotification";
import AppResult from "components/appResults";
import CertificationBadge from "components/certificationBadge";
import AppButton from "components/libs/button";
import H2 from "components/libs/subtitle";
import useWebhook from "hooks/useWebHook";
import { badRequest } from "messages";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setUserConnect } from "store/auth/authSlice";
import * as TEACHER_SERVICES from "../../../../api/services/teacher/teacher.services";
import CardCreneau from "./card";
import TeacherCalendarDetails from "./drawerDetails";
import s from "./style.module.css";

type Props = {
  open: boolean;
  currentTeacher: any;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

interface Niche {
  date: string;
  level: {
    _id: string;
    title: string;
    levelGroup: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
  };
  topic: {
    _id: string;
    title: string;
    level: string;
    status: string;
    position: number;
    language: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
    description: string;
    price: string;
  };
  timeRanges: string[];
  idCreneau: string;
  reservations: any[];
  status: any[];
  id: number;
  day: string;
}

interface Obj {
  day: string;
  niches: Niche[];
}

const { Text } = Typography;

export const handleRemoveDoublonNiveau = (res: any[]) => {
  return res?.filter(
    (arr: any, index: number, self: any[]) =>
      index ===
      self?.findIndex(
        (t: any) =>
          t?.levelGroup === arr?.levelGroup &&
          t?.levelGroup?._id === arr?.levelGroup?._id
      )
  );
};

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  className: s.slider,
  responsive: [
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 3,
      },
    },
  ],
};

const TeacherCalendarForStudent: FC<Props> = ({
  setOpen,
  open,
  currentTeacher,
}) => {
  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [sendWebhook] = useWebhook();
  const [creneau, setCreneau] = useState<any>([]);
  const [index, setIndex] = useState<number>(0);
  const [allData, setAllData] = useState<any>([]);
  const [allSuccessRes, setAllSuccessRes] = useState<any>([]);
  const [allCreneaux, setAllCreneaux] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAllData, setIsAllData] = useState<boolean>(true);
  const [drawerRes, setDrawerRes] = useState<boolean>(false);
  const [creditAllowed, setCreditAllowed] = useState<boolean>(false);
  const dateRef = useRef<(HTMLDivElement | null)[]>([]);
  const [confirmReservations, setConfirmRes] = useState<any[]>([]);

  // const {
  //   data: calendar,
  //   isLoading,
  //   isSuccess,
  //   refetch,
  // } = useGetCalendarTeacherQuery(currentTeacher?.teacher?._id);

  const generateNiches = useCallback(
    (data: any) => {
      const niches = [];
      if (Array.isArray(data?.niches) && data?.niches?.length > 0) {
        const topics = Array.isArray(data?.niches[0]?.topic)
          ? data?.niches[0]?.topic
          : [];
        const levels = Array.isArray(data?.niches[0]?.level)
          ? data?.niches[0]?.level
          : [];
        const topicsLength = topics?.length;
        const levelsLength = levels?.length;

        for (let i = 0; i < topicsLength; i++) {
          for (let j = 0; j < levelsLength; j++) {
            const filteredTopics = data?.niches.map((n: any) =>
              Array.isArray(n.topic) && Array.isArray(n?.level)
                ? n?.topic.filter(
                  (t: any) =>
                    t?.level === n?.level[j]._id && t?.position === i + 1
                )
                : []
            );

            const foundTopic = filteredTopics?.find((n: any) => n?.length > 0);

            if (foundTopic) {
              const niche = {
                date: data.niches[0].date,
                level: data.niches[0].level[j],
                topic: foundTopic[j],
                timeRanges: data.niches[0].timeRanges[j],
                idCreneau: data.niches[0].idCreneau,
                reservations:
                  data.niches[0] && data.niches[0].reservations
                    ? data.niches[0].reservations[j] || []
                    : [],
                status: data.niches[0].status[j] || [],
                annonce: data.annonce,
                id: j + 1,
                creneauTypes: data.niches[0].creneauTypes[j] || [],
                day: data.day,
              };
              niches.push(niche);
            }
          }
        }
      }

      const output = { day: data.day, niches: niches };
      setCreneau(output);
      return output;
    },
    [creneau]
  );

  const sumPrices = useCallback(
    (arr: any[]) => {
      let total = 0;

      for (let obj of arr) {

        let price = obj?.topic?.price;

        if (typeof price !== "number") {

          price = Number(price);

          if (isNaN(price)) {
            throw new Error(
              "Chaque objet doit avoir une propriété 'price' numérique"
            );
          }
        }

        total += price;
      }

      return total;
    },
    [confirmReservations]
  );

  const fetchCalender = useCallback(
    async (idTeacher: string) => {
      setLoading(true);
      if (currentTeacher?.teacher?._id) {
        try {
          const response = await axios.get(
            `${API_RESSOURCES}/advertisements/fetch/ReelCalender/${idTeacher}/${user?._id}`
          );
          setAllData(response.data?.data || []);

          if (allCreneaux?.length <= 0) {
            const result = response.data?.data
              .filter((data: any) => data?.niches.length > 0)
              .map((niche: any) => generateNiches(niche));

            setAllCreneaux(result);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    },
    [currentTeacher?.teacher?._id]
  );

  const handleConfirmReservations = useCallback(async () => {
    if (!currentTeacher?._id || !creneau?.day || !confirmReservations?.length) {
      console.error("Missing required variables for confirming reservations");
      return;
    }

    try {
      const requests = confirmReservations.map((reservation) => {
        const finalData = {
          studentId: user?._id,
          profId: currentTeacher?.teacher?._id,
          advertisementId: reservation?.annonce?._id,
          level: reservation?.level?._id,
          topic: reservation?.topic?._id,
          range: reservation?.timeRanges,
          idCreneau: reservation?.idCreneau,
          startDate:
            reservation?.day?.split("T")[0] +
            "T" +
            `${reservation?.timeRanges[0]}:00.000Z`,
          type:
            typeof reservation?.creneauTypes === "string"
              ? reservation?.creneauTypes
              : reservation?.creneauTypes.creneauTypes[0],
        };

        return findReservationStudent(currentTeacher?._id, finalData);
      });

      const responses = await Promise.all(requests);

      responses.forEach((response, i) => {
        if (response?.status === "OK") {
          AppNotification(
            `Réservation ${i + 1} effectuée avec succès`,
            "AlbertHappy"
          );
          fetchCalender(currentTeacher?.teacher?._id);
          setAllSuccessRes((res: any) => [...res, response.data]);
          sendWebhook(response.data);
          localStorage.setItem("reservations", allSuccessRes);
          // setDrawerRes(true);
          setConfirmRes([]);
        } else {
          console.error(`Error confirming reservation ${i + 1}`, response);
          AppNotification(badRequest.error, "AlbertSad");
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.data?.status === "KO") {
          AppNotification(
            "Ce prof a déjà une réservation en cours.",
            "AlbertSad"
          );
          fetchCalender(currentTeacher?.teacher?._id);
        }
      } else {
        console.error("Error confirming reservations", error);
        AppNotification(badRequest.error, "AlbertSad");
      }
    }
  }, [currentTeacher, creneau, confirmReservations, allSuccessRes]);

  const handleStudentAttribution = async (teacherId: string) => {
    try {
      const response = await TEACHER_SERVICES.getStudentsAttibutions(teacherId, user?._id);
      if (response?.statusCode === StatusCode.OK) {
        const credit = response?.data[0]?.creditsForStudent;
        if (typeof credit === 'number') {
          setCreditAllowed(true);
        } else {
          setCreditAllowed(false);
        }
      } else {
        throw new Error("Error");
      }
    } catch (error) { }
  }

  useEffect(() => {
    (async () => {
      if (open) {
        await fetchCalender(currentTeacher?.teacher?._id);
      }
    })();
  }, [open, fetchCalender]);

  useEffect(() => {
    if (open === false) {
      setAllData([]);
      setAllCreneaux([]);
      setConfirmRes([]);
      setAllSuccessRes([]);
    }
  }, [open]);

  useEffect(() => {
    if (currentTeacher?.teacher?._id) {
      if (creneau?.day === confirmReservations[0]?.day) {
        // dateRef.current[index]?.click();
      }
    }
  }, [currentTeacher]);

  useEffect(() => {
    const filterReservations = (allCreneaux: any[]): any[] => {
      const reservations: any[] = allCreneaux.filter((creneau: any) => {
        return creneau.niches.some((niche: any) => {
          return niche.reservations && niche.reservations._id;
        });
      });

      const successReservations: any[] = reservations.reduce(
        (acc: any[], creneau: any) => {
          const niche = creneau.niches[0];
          if (niche.reservations) {
            acc.push({
              ...niche.reservations,
              topic: niche.topic,
            });
          }
          return acc;
        },
        []
      );

      return successReservations;
    };

    const myReservations: any[] = filterReservations(allCreneaux);

    setAllSuccessRes((res: any) => [...res, myReservations]);
  }, [open, allCreneaux]);

  useEffect(() => {
    if (open) {
      if (!isLoggedIn) {
        setOpen(false);
        dispatch(setUserConnect(true));
      }
    }
  }, [isLoggedIn, open]);

  useEffect(() => {
    handleStudentAttribution(currentTeacher?.teacher?._id);
  }, [currentTeacher, user]);

  return (
    <AppDrawer
      title={
        <Row justify="center" gutter={12} align="middle">
          {creditAllowed && <div style={{ height: "35px", width: "170px", background: "#a9e0e4", marginBottom: "0px", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "12px", fontFamily: "Poppins", color: "white", borderRadius: "8px", fontWeight: "400", padding: "4px" }}>
            <div style={{ background: "#669e9d", borderRadius: "5px", height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", paddingLeft: "10px", paddingRight: "10px" }}><div><img src={starCoins} height={15}></img> Crédit </div> <div style={{ fontSize: "1.2em" }}>{currentTeacher?.credits}<span style={{ fontSize: "0.8em", position: "relative", bottom: "5px" }}> CDT</span></div></div>
          </div>}
          <Col>
            <Avatar
              size={40}
              style={{ backgroundColor: "#7fb1b2" }}
              src={currentTeacher?.teacher?.profile || TEACHER}
            />
          </Col>{" "}
          <Col>
            <H2>
              {currentTeacher?.teacher?.firstname?.split(" ")[0]}{" "}
              {currentTeacher?.teacher?.lastname?.split(" ")[0]}
              {currentTeacher?.teacher?.certified && <a style={{ position: "relative", bottom: "5px" }}><CertificationBadge /></a>}
            </H2>
          </Col>
        </Row>
      }
      visible={open}
      onClose={() => {
        setOpen(false);
        setAllSuccessRes([]);
        setCreneau([]);
      }}
    >
      {loading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <Fragment>
          {allData?.length > 0 ? (
            <div className={s.calendar}>
              <div className={s.weekly}>
                <Slider {...settings}>
                  {allData?.map((item: any, index: number) => (
                    <div
                      key={index}
                      ref={(el) => (dateRef.current[index] = el)}
                      onClick={() => {
                        generateNiches(item);
                        setConfirmRes([]);
                        setIndex(index);
                        // setAllCreneaux([]);
                        setIsAllData(false);
                      }}
                      className={`${s.date} ${creneau?.day === item?.day && s.active
                        }`}
                    >
                      <span>{moment(item?.day).format('dddd D MMMM YYYY')}</span>
                    </div>
                  ))}
                </Slider>
              </div>

              <Row
                justify={confirmReservations.length > 0 ? "start" : "end"}
                gutter={12}
              >
                <Col>
                  <div className={s.myReservation}>
                    <Button
                      onClick={() => {
                        setConfirmRes([]);
                        setIsAllData(true);
                      }}
                    >
                      Tout
                    </Button>
                  </div>
                </Col>

                {/* <Col>
                  <div className={s.myReservation}>
                    <Button onClick={() => setDrawerRes(true)}>
                      Mes reservations
                    </Button>
                  </div>
                </Col> */}
              </Row>

              {isAllData ? (
                allCreneaux?.map((cren: any, index: number) => (
                  <div key={index}>
                    <Row
                      justify="space-between"
                      align="middle"
                      style={{ marginBottom: "2em" }}
                    >
                      {cren?.niches && !cren.niches.every((niche: any) => niche.status === 'deleted') && (
                        <Col>
                          <H2>{moment(cren?.day).format('dddd D MMMM YYYY')}</H2>
                        </Col>
                      )}
                      <Col>
                        {confirmReservations.reduce((acc, reservation) => {
                          if (reservation.day === cren.day) {
                            return (
                              creditAllowed ? <Fragment>
                                {confirmReservations.length > 0 && (
                                  <Fragment>
                                    {currentTeacher?.credits <
                                      sumPrices(confirmReservations) ? (
                                      <Text type="danger">
                                        Vous n'avez pas suffisamment de "crédit"
                                        pour effectuer cette réservation.
                                      </Text>
                                    ) : (
                                      <Fragment>
                                        <Popconfirm
                                          placement="top"
                                          title={
                                            "Veux-tu vraiment faire la réservation ?"
                                          }
                                          onConfirm={handleConfirmReservations}
                                          okText="Oui"
                                          cancelText="Non"
                                        >
                                          <AppButton>
                                            Réserver{" "}
                                            {confirmReservations?.length > 1
                                              ? "les" +
                                              " " +
                                              confirmReservations?.length
                                              : " "}{" "}
                                            pour{" "}
                                            {sumPrices(confirmReservations)}{" "}
                                            {confirmReservations?.length > 1 ? "crédits" : "crédit"}
                                          </AppButton>
                                        </Popconfirm>
                                      </Fragment>
                                    )}
                                  </Fragment>
                                )}
                              </Fragment> : <Fragment>
                                {confirmReservations.length > 0 && (
                                  <Fragment>
                                    {user.coins <
                                      sumPrices(confirmReservations) ? (
                                      <Text type="danger">
                                        Vous n'avez pas suffisamment de "coins"
                                        pour effectuer cette réservation. Coût :{" "}
                                        {sumPrices(confirmReservations)} euros
                                      </Text>
                                    ) : (
                                      <Fragment>
                                        <Popconfirm
                                          placement="top"
                                          title={
                                            "Veux-tu vraiment faire la réservation ?"
                                          }
                                          onConfirm={handleConfirmReservations}
                                          okText="Oui"
                                          cancelText="Non"
                                        >
                                          <AppButton>
                                            Réserver{" "}
                                            {confirmReservations?.length > 1
                                              ? "les" +
                                              " " +
                                              confirmReservations?.length
                                              : " "}{" "}
                                            pour{" "}
                                            {sumPrices(confirmReservations)}{" "}
                                            €
                                          </AppButton>
                                        </Popconfirm>
                                      </Fragment>
                                    )}
                                  </Fragment>
                                )}
                              </Fragment>
                            );
                          }
                          return acc;
                        }, [])}
                      </Col>
                    </Row>

                    {cren?.niches?.map((c: any, index: number) => {
                      return (
                        c?.status !== "deleted" && c?.topic !== undefined && (<CardCreneau
                          isAllReservation={false}
                          key={index}
                          cren={c}
                          index={index}
                          currentTeacher={currentTeacher}
                          confirmReservations={confirmReservations}
                          creditAllowed={creditAllowed}
                          setConfirmRes={setConfirmRes}
                        />)
                      );
                    })}
                  </div>
                ))
              ) : (
                <>
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "2em" }}
                  >
                    <Col>
                      <H2>{moment(creneau?.day).format('dddd D MMMM YYYY')}</H2>
                    </Col>
                    <Col>
                      {confirmReservations.length > 0 && (
                        creditAllowed ? <Fragment>
                          {currentTeacher?.credits < sumPrices(confirmReservations) ? (
                            <Text type="danger">
                              Vous n'avez pas suffisamment de "crédit" pour
                              effectuer cette réservation.
                            </Text>
                          ) : (
                            <Fragment>
                              <Popconfirm
                                placement="top"
                                title={
                                  "Veux-tu vraiment faire la réservation ?"
                                }
                                onConfirm={handleConfirmReservations}
                                okText="Oui"
                                cancelText="Non"
                              >
                                <AppButton>
                                  Réserver{" "}
                                  {confirmReservations?.length > 1
                                    ? "les" +
                                    " " +
                                    confirmReservations?.length
                                    : " "}{" "}
                                  pour{" "}
                                  {sumPrices(confirmReservations)}{" "}
                                  {confirmReservations?.length > 1 ? "crédits" : "crédit"}
                                </AppButton>
                              </Popconfirm>
                            </Fragment>
                          )}
                        </Fragment> : <Fragment>
                          {user.coins < sumPrices(confirmReservations) ? (
                            <Text type="danger">
                              Vous n'avez pas suffisamment de "coins" pour
                              effectuer cette réservation. Coût :{" "}
                              {sumPrices(confirmReservations)} euros
                            </Text>
                          ) : (
                            <Fragment>
                              <Popconfirm
                                placement="top"
                                title={
                                  "Veux-tu vraiment faire la réservation ?"
                                }
                                onConfirm={handleConfirmReservations}
                                okText="Oui"
                                cancelText="Non"
                              >
                                <AppButton>
                                  Réserver{" "}
                                  {confirmReservations?.length > 1
                                    ? "les" + " " + confirmReservations?.length
                                    : " "}{" "}
                                  pour {sumPrices(confirmReservations)} €
                                </AppButton>
                              </Popconfirm>
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                    </Col>
                  </Row>

                  {creneau?.niches?.length <= 0 ? (
                    <AppResult
                      status="info"
                      title={`Ce professeur n'a aucune disponibilité pour le ${moment(
                        creneau?.day
                      ).format("LL")}`}
                    />
                  ) : (
                    <Fragment>
                      <>
                        {creneau?.niches?.map((cren: any, index: number) => {
                          return (
                            cren?.status !== "deleted" && cren?.topic !== undefined && <CardCreneau
                              key={index}
                              cren={cren}
                              index={index}
                              isAllReservation={false}
                              currentTeacher={currentTeacher}
                              confirmReservations={confirmReservations}
                              creditAllowed={creditAllowed}
                              setConfirmRes={setConfirmRes}
                            />
                          );
                        })}
                        {creneau?.niches && creneau.niches.every((niche: any) => niche.status === 'deleted') && (
                          <AppResult
                            status="info"
                            title={`Ce professeur n'a aucune disponibilité pour le ${moment(
                              creneau?.day
                            ).format("LL")}`}
                          />
                        )}
                      </>
                    </Fragment>
                  )}
                </>
              )}
            </div>
          ) : (
            <AppResult
              status={"info"}
              title="Ce professeur n'a aucune disponibilité"
            />
          )}
        </Fragment>
      )}

      <TeacherCalendarDetails
        currentTeacher={currentTeacher}
        drawerRes={drawerRes}
        allSuccessRes={allSuccessRes}
        setDrawerRes={setDrawerRes}
      />
    </AppDrawer>
  );
};

export default TeacherCalendarForStudent;
