import React, { FC } from "react";
import { Col, Divider, Row } from "antd";
import { motion } from "framer-motion";

import Teacher from "assets/images/teacher/teacher.svg";
import StartIcon from "assets/images/students/teacherStart.svg";

//!STYLES
import s from "./assets/style.module.css";

type Props = {
  av: any;
};
const CardAvis: FC<Props> = ({ av }) => {
  return (
    <div className={s.card}>
      <Row gutter={12} align="middle">
        <Col>
          <div className={s.student}>
            <Row gutter={12} align="middle">
              <Col>
                <div className={s.avatar}>
                  <img src={av?.studId?.profile || Teacher} alt="" width={35} />
                </div>
              </Col>
              <Col>
                <span>
                  {av?.studId?.firstname?.split(" ")[0]}{" "}
                  {av?.studId?.lastname?.split(" ")[0]}
                </span>
              </Col>
            </Row>
          </div>
        </Col>

        <Col>
          <div className={s.tagAvis}>
            <img
              src={StartIcon}
              alt=""
              width={17}
              style={{ position: "relative", top: "-2px", marginRight: "5px" }}
            />

            <span>
              <strong>{av?.rate || 0}</strong>
            </span>
          </div>
        </Col>
      </Row>

      <div className={s.description}>
        <p>{av?.content}</p>
      </div>

      <Divider />
    </div>
  );
};

export default CardAvis;
