import { Drawer } from 'antd';
import 'antd/dist/antd.css';
import AppLatex from 'components/appLatex';
import React, { useState } from 'react';

type Props = {
    data: any,
    visible: boolean,
    onClose: () => void,
    userImage: any,
    userName: any,
    userLastname: any,
}

const LeftDrawer: React.FC<Props> = ({ data, visible, onClose, userImage, userName, userLastname }) => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <div>
            <Drawer
                title={<div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ textTransform: "uppercase", fontSize: "1.5em" }}>{userName} {userLastname}</div>
                    <div style={{ borderRadius: "50%" }}><img src={userImage} height={40} width={40} style={{ borderRadius: "50%" }}></img></div>
                </div>}
                placement="left"
                closable={true}
                onClose={onClose}
                visible={visible}
                width={isMobile ? "100%" : "30%"}
                headerStyle={{ display: "flex", justifyContent: "space-between" }}
            >
                <AppLatex content={data} />
            </Drawer>
        </div>
    );

};

export default LeftDrawer;
