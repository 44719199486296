import { Col, Image, Tooltip } from "antd";
import { FC } from "react";

//IMAGES
import ANGLAIS from "assets/images/app/iconMatiers/Anglais.png";
import FRANCAIS from "assets/images/app/iconMatiers/Francais.png";
import HISTOIREGEO from "assets/images/app/iconMatiers/Histoire-geo.png";
import MATH from "assets/images/app/iconMatiers/Mathematiques.png";
import PHILO from "assets/images/app/iconMatiers/Philosophie.png";
import PHYSIQUE from "assets/images/app/iconMatiers/Physique-chimie.png";
import SVT from "assets/images/app/iconMatiers/SVT.png";

//!STYLES
import { TypeCours } from "common/enums";
import s from "./assets/matiers.module.css";

interface Matires {
  className?: string;
  cours?: string;
  level?: string;
  classNameIcon?: string;
  style?: React.CSSProperties;
}

const Matiers: FC<Matires> = ({
  cours,
  level,
  className,
  classNameIcon,
  style,
}) => {
  return (
    <div className={`${s.matiers} ${className}`} style={style}>
      <div className={s.left}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
          <Col>
            <Image
              className={classNameIcon}
              preview={false}
              src={
                cours == TypeCours.mathematique
                  ? MATH
                  : cours == TypeCours.SVT
                    ? SVT
                    : cours == TypeCours.PhysiqueChimie
                      ? PHYSIQUE
                      : cours == TypeCours.Philosophie
                        ? PHILO
                        : cours == TypeCours.HistoireGeo
                          ? HISTOIREGEO
                          : cours == TypeCours.Francais
                            ? FRANCAIS
                            : cours == TypeCours.Anglais
                              ? ANGLAIS
                              : MATH
              }
              width={23}
            />
          </Col>
          <Col>
            <Tooltip title={cours}>
              <span>{cours?.substring(0, 4)}</span>
            </Tooltip>
          </Col>
        </div>
      </div>
      <div className={s.center}>
        <span>|</span>
      </div>
      <div className={s.right}>
        <span>{level?.substring(0, 4)}</span>
      </div>
    </div>
  );
};

export default Matiers;
