import "katex/dist/katex.min.css";
import { FC } from "react";
import Latex from "react-latex-next";

type Props = {
  content: string;
};

const AppLatex: FC<Props> = ({ content }) => {


  // Regular expression to match YouTube URLs
  const youtubeRegex = /(https?:\/\/(www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+))/g;

  // Split the message into parts - text and YouTube video link
  const messageParts = content?.split(youtubeRegex) || [];

  // Extract the video ID from the regex match
  const videoId = content?.match(youtubeRegex)?.[0]?.split('v=')?.[1] || "";

  return (
    <div>
      {/* Display the text part first */}
      <p>
        <Latex>
          {messageParts?.[0]?.replaceAll("amp;", "").replaceAll("<br />", "") || ""}
        </Latex>
      </p>

      {/* Display YouTube video if there is a match */}
      {videoId && (
        <div>
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${videoId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="YouTube video"
          ></iframe>
        </div>
      )}

      {/* Display any text after the video link */}
      {messageParts?.length > 1 && <p>
        <Latex>
          {messageParts[messageParts.length - 1] ? messageParts[messageParts.length - 1]?.replaceAll("amp;", "")?.replaceAll("<br />", "") : ""}
        </Latex>
      </p>}
    </div>
  );

  /* return (
    <Latex>
      {content ? content?.replaceAll("amp;", "")?.replaceAll("<br />", "") : ""}
    </Latex>
  ); */
};

export default AppLatex;
