import {
  Alert,
  Card,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Skeleton,
  Typography,
  message,
} from "antd";
import SOCIAL_SHARING from "../../../components/libs/readyToUse/socialNetworks";

import React, { FC, useCallback, useEffect, useState } from "react";
// import { RoomCard } from '../dashboard'
import { PlusOutlined } from "@ant-design/icons";
import * as ROOMS_SERVICES from "api/services/rooms/rooms.services";
import { StatusCode } from "common/enums";
import Breadcrumbs from "components/breadcrumbs";
import AppButton from "components/libs/button";
import CardAddCours from "components/libs/cardAddCours";
import H2 from "components/libs/subtitle";
import * as appRoutes from "config/routes.config";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppScreen from "../ScreenWithSidebar";
import { default as s, default as styles } from "./style.module.css";
// !COPYtoCLIP-BOARD
import { CopyToClipboard } from "react-copy-to-clipboard";
//!IMAGES
import AppDrawer from "components/appDrawer";
import AppResult from "components/appResults";
import HelpPopover from "components/help";
import Flex from "components/libs/flex";
import CardClasses from "screens/teacher/components/cardClasses";
import { useGetAdHistoriqueQuery } from "store/services/teacher/ads.services";
import { useGetHistoriqueQuery } from "store/services/teacher/classes.services";
import Close from "../../../assets/images/app/close.svg";
import { ReactComponent as SarchIcon } from "../../../assets/images/app/search.svg";
import Success from "../../../assets/images/teacher/success.svg";
import Matiers from "../../../components/matiers";
import AddRoom from "../components/addRoom";
import CardHistory from "./components/cardHistoryCours";

const { Paragraph, Text } = Typography;
const { Option } = Select;
const { Search } = Input;
const MESSAGE_PREFIX_PARTAGE_RESEAUX_SOCIAUX =
  "Bonjour ! Rejoins ma classe sur Monamilabert.com en utilisant ce code :";
const Rooms: FC = () => {
  //!HOOKS
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const [addCourseForm] = Form.useForm();
  const topics = useSelector((state) => state.topics);
  const levels = useSelector((state) => state.levels);
  const { data, isLoading, isError } = useGetHistoriqueQuery(auth?.user?._id);
  const {
    data: adHistorique,
    isLoading: adHistoriqueLoading,
    isError: adHistoriqueisError,
  } = useGetAdHistoriqueQuery(auth?.user?._id);

  //!STATE
  const [showAddCourseModal, setShowAddCourseModal] = useState(false);
  const [coursesLoading, setCoursesLoading] = useState(false);
  const [coursesError, setCoursesError] = useState("");
  const [rooms, setRooms] = useState<any>([]);
  const [addCourseLoading, setAddCourseLoading] = useState(false);
  const [addCourseError, setAddCourseError] = useState("");
  const [showAddClasse, setShowAddClasse] = useState(false);
  const [isModalSuccessAddCours, setIsModalSuccessAddCours] =
    useState<boolean>(false);
  const [tempRoom, setTempRoom] = useState<any>(null);
  const [tempTopic, setTempTopic] = useState<any>();
  const [visibleSocialMedia, setVisibleSocialMedia] = useState(false);

  const [code, setCode] = useState(null);

  //!FUNCTIONS
  const handleAddCourseOk = () => setShowAddCourseModal(true);
  const openAddCourseModal = () => setShowAddCourseModal(true);
  const goToTeacherRooms = () => navigate(appRoutes.TEACHER_ROOMS);
  const closeAddCourseModal = () => setShowAddCourseModal(false);
  const handleVisibleChange = () => setVisibleSocialMedia(!visibleSocialMedia);

  const fetchCourses = useCallback(async (id: string | undefined) => {
    try {
      setCoursesLoading(true);
      const response = await ROOMS_SERVICES.findByLevel(id, auth?.user?._id);
      if (response?.statusCode === StatusCode.OK) {
        setRooms(response.data);
      } else {
        setCoursesError(response?.message || "Erreur serveur");
      }
    } catch (error: any) {
      console.log(error);
      setCoursesError(error?.message || "error");
    } finally {
      setCoursesLoading(false);
    }
  }, []);

  const addCourse = useCallback(
    async (course: any) => {
      try {
        setAddCourseLoading(true);
        setAddCourseError("");

        const final = {
          ...course,
          teacherId: auth.user?._id,
        };

        const response = await ROOMS_SERVICES.createRoom(final);
        if (response?.statusCode === StatusCode.CREATED) {
          setTempRoom(response?.data);
          setRooms((prev: any) => [...prev, response.data]);
          setShowAddCourseModal(false);
          message.success("créé avec succès !");
          fetchCourses(auth.user?._id);
          addCourseForm.resetFields();
          setShowAddClasse(false);
          setTimeout(() => {
            setIsModalSuccessAddCours(true);
            setCode(response.data);
          }, 1500);
        } else {
          setAddCourseError(response?.message || "Erreur serveur");
        }
      } catch (error: any) {
        console.log(error);
        setAddCourseError(error?.message || "error");
        message.error(error?.message || "error");
      } finally {
        setAddCourseLoading(false);
      }
    },
    [addCourseForm]
  );
  const hide = () => setVisibleSocialMedia(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  //!EFFECTS
  useEffect(() => {
    fetchCourses(auth.user?._id);
  }, [auth.user?._id, fetchCourses]);

  return (
    <>
      <Breadcrumbs isRooms={true} />
      <AppScreen>
        <div className={s.header}>
          <div className={s.left}>
            <H2 className={s.subtitle}>Classe pour Cours particuliers</H2>
          </div>

          <div className={s.center} />

          <div className={s.right}>
            <AppButton icon={<PlusOutlined />} onClick={handleAddCourseOk}>
              Historique des cours
            </AppButton>
          </div>
        </div>

        <div>
          <>
            <div className={s.main}>
              <Card
                bordered={false}
                headStyle={{ borderBottom: "unset" }}
                bodyStyle={{ margin: 0, padding: 0 }}
                loading={coursesLoading}
              >
                {/* {!coursesError && (
                  <div className={s.row}>
                    <H2 className={s.__msg__not_cours}>
                      Aucune cours particulier planifié pour le moment !
                    </H2>
                  </div>
                )} */}

                {!isLoading && (
                  <Flex>
                    {data?.data?.map((item: any) => (
                      <CardHistory item={item} />
                    ))}
                  </Flex>
                )}

                {!isLoading && data?.data.length <= 0 && (
                  <AppResult
                    status={"info"}
                    title="Tu n’as aucune réservation"
                  />
                )}

                {coursesError && (
                  <Alert
                    message="Une erreur est survenue !"
                    description={coursesError}
                    type="error"
                  />
                )}
              </Card>
            </div>

            <Row justify="center" style={{ marginTop: "4em" }}>
              {/* <Col>
                <AppButton className={""}>Tous mes cours particulier</AppButton>
              </Col> */}
            </Row>

            <div className={s.header}>
              <div className={s.left}>
                <H2 className={s.subtitle}>Mes classes</H2>
                <div style={{ position: "relative", top: "15px", marginLeft: "10px" }}><HelpPopover id={"dashboard_mes_classes"} /></div>
              </div>

              <div className={s.center} />

              <div className={s.right}>
                <AppButton
                  icon={<PlusOutlined />}
                  onClick={() => setShowAddClasse(!showAddClasse)}
                >
                  Créer une classe
                </AppButton>
              </div>
            </div>

            <div className={s.main}>
              <Card
                bordered={false}
                headStyle={{ borderBottom: "unset" }}
                bodyStyle={{ margin: 0, padding: 0 }}
                loading={coursesLoading}
              >
                {!coursesError && (
                  <Flex>
                    {!coursesLoading &&
                      rooms?.map((course: any) => (
                        <React.Fragment>
                          <CardClasses course={course} />
                        </React.Fragment>
                      ))}
                    {rooms.length === 0 && (
                      <CardAddCours
                        onClick={() => setShowAddClasse(!showAddClasse)}
                      />
                    )}
                  </Flex>
                )}
                {coursesError && (
                  <Alert
                    message="Une erreur est survenue !"
                    description={coursesError}
                    type="error"
                  />
                )}
              </Card>
            </div>
          </>
        </div>

        {/* Drawer add Cours */}
        <AppDrawer
          title={"Historique des cours"}
          onClose={() => setShowAddCourseModal(!showAddCourseModal)}
          visible={showAddCourseModal}
          width={isMobile ? "100%" : "50%"}
        >
          <div className={styles.__first_drawer}>
            <div className={styles.drawer__content}>
              <Form name="create-room" autoComplete="off">
                <div className={styles.filter}>
                  {/* <div className={styles.filter__left}></div> */}

                  <div className={styles.filter__right}>
                    <div className={styles.filed__search}>
                      <Search
                        placeholder="Tape ici ta recherche"
                        size="large"
                        bordered={false}
                        enterButton={
                          <SarchIcon
                            style={{
                              position: "relative",
                              width: "30px",
                              top: "3px",
                            }}
                          />
                        }
                      />
                    </div>

                    <Row gutter={12} align="middle">
                      <Col>
                        <div className={`${styles.select}`}>
                          <Form.Item name="levelId">
                            <Select
                              placeholder="Choisir un niveau"
                              allowClear
                              loading={levels?.loading}
                              bordered={false}
                            >
                              {levels?.items?.map((level) => (
                                <Option key={level._id} value={level._id}>
                                  {level.title}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>

                      <Col>
                        <div className={`${styles.select}`}>
                          <Form.Item name="topicId">
                            <Select
                              placeholder="Choisir une matière"
                              allowClear
                              loading={topics.loading}
                              bordered={false}
                            >
                              {topics.items.map((topic) => (
                                <Option key={topic._id} value={topic._id}>
                                  {topic.title}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Form>

              {adHistoriqueLoading ? (
                <>
                  <Skeleton active />
                  <Skeleton active />
                  <Skeleton active />
                </>
              ) : (
                <>
                  {!adHistoriqueLoading && adHistorique?.data.length <= 0 ? (
                    <AppResult
                      status={"info"}
                      title="Tu n’as aucune réservation"
                    />
                  ) : (
                    <Flex>
                      {adHistorique?.data?.map((item: any) => (
                        <CardHistory item={item} />
                      ))}
                    </Flex>
                  )}
                </>
              )}
            </div>
          </div>
        </AppDrawer>

        {/* Drawer Add Rooms */}
        <AddRoom
          addCourse={addCourse}
          addCourseLoading={addCourseLoading}
          showAddClasse={showAddClasse}
          setShowAddClasse={setShowAddClasse}
          addCourseForm={addCourseForm}
        />

        {/* Modal add cours */}
        <Modal
          title={null}
          closable={false}
          centered
          visible={isModalSuccessAddCours}
          onCancel={() => setIsModalSuccessAddCours(false)}
          footer={null}
          width={800}
        >
          <div className={styles.__success__modal_container}>
            <div className={styles.modal__card}>
              <h3 className={styles.modal__card__title}>
                Classe de {tempRoom?.title}
              </h3>
              <Matiers
                level={tempRoom?.level?.title}
                cours={tempRoom?.course?.title}
                className={styles.__matier}
                classNameIcon={styles.__icon_matiere}
              />
              <div className={styles.__modal__img__success}>
                <Image preview={false} src={Success} width={100} />
              </div>

              <span className={styles.__code__access__title}>
                Code d’accès{" "}
              </span>

              <div className={styles.__code}>
                <span>
                  {tempRoom?.accessCode.slice(0, 3) +
                    " - " +
                    tempRoom?.accessCode.slice(3, 6)}
                </span>
              </div>

              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                <div>
                  <CopyToClipboard
                    text={tempRoom?.accessCode}
                    onCopy={() =>
                      message.success({
                        content: "copié dans le presse-papiers",
                      })
                    }
                  >
                    <AppButton className={styles.__btn}>Copier</AppButton>
                  </CopyToClipboard>{" "}
                </div>
                <div>
                  <Popover
                    content={
                      <>
                        <div>
                          {" "}
                          <SOCIAL_SHARING
                            prefix={MESSAGE_PREFIX_PARTAGE_RESEAUX_SOCIAUX}
                            codeAcces={tempRoom?.accessCode + ", à bientôt"}
                          />
                        </div>
                        <div style={{ width: "100%", textAlign: "center" }}>
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              hide();
                            }}
                            href="/"
                          >
                            Fermer
                          </a>
                        </div>
                      </>
                    }
                    trigger="click"
                    visible={visibleSocialMedia}
                    onVisibleChange={handleVisibleChange}
                  >
                    {" "}
                    <AppButton className={styles.__btn}>partager</AppButton>
                  </Popover>{" "}
                </div>
              </div>
            </div>

            <div
              className={styles.close}
              onClick={() => setIsModalSuccessAddCours(false)}
            >
              <Image preview={false} src={Close} width={25} />
            </div>
          </div>
        </Modal>
      </AppScreen>
    </>
  );
};

export default Rooms;
