import * as AUTH_SERVICES from "api/services/auth/auth.services";
import * as appRoutes from "config/routes.config";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import AppScreen from "../ScreenWithSidebar";

import { AutoComplete, Skeleton, notification } from "antd";
import AppResult from "components/appResults";
import Breadcrumbs from "components/breadcrumbs";
import H1 from "components/libs/title";
import s from "./style.module.css";

import { useNavigate } from "react-router-dom";
import Search from "../../../assets/images/app/search.svg";
import Star from "../../../assets/images/app/start.svg";



const TeacherStudents: FC = () => {
  //!HOOKS
  const auth = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth);
  const [page, setPage] = useState<any>("1");
  const [studentsData, setStudentsData] = useState<any>([]);
  const [searchStudentsData, setSearchStudentsData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  const getStudents = async () => {
    try {
      const students = await AUTH_SERVICES.getTeacherStudents("", user?._id, page);
      if (students?.data) {
        setStudentsData(students['data']['results']);
        console.log(studentsData);
        console.log(students['data']['results']);
      } else {
        throw new Error("L'utilisateur n'existe pas");
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  }

  const FindStudent = useCallback(async (userNameOrEmail: string, item) => {
    if (userNameOrEmail.length >= 3) {
      try {
        const res = await AUTH_SERVICES.getTeacherStudents(userNameOrEmail, user?._id, "1");
        const data = res?.data?.results;
        console.log(res?.data?.results);
        setSearchStudentsData(data);
        if (res?.data?.results.length === 0) {
          notification["info"]({
            message: "Information",
            description: "Utilisateur non trouvé : )",
            style: {
              width: 300,
            },
          });
        }
      } catch (error) {
        if (userNameOrEmail.length >= 6) {
          notification["info"]({
            message: "Information",
            description: "Utilisateur non trouvé : )",
            style: {
              width: 300,
            },
          });
        }
      }
    }
    else {
      setSearchStudentsData([]);
    }
  }, []);

  useEffect(() => {
    getStudents();
  }, [page]);

  return (
    <React.Fragment>
      <Breadcrumbs isStudents />
      <AppScreen>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <H1>Mes élèves</H1>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", border: "1px solid #D5D5D5", borderRadius: "16px", marginTop: "30px", position: "relative", bottom: "12.5px", width: "30%", paddingRight: "10px" }}>
            <AutoComplete
              bordered={false}
              style={{ margin: "0px", paddingLeft: "10px", paddingRight: "10px", width: "100%" }}
              onChange={FindStudent}
              placeholder="Trouver un élève"
              showSearch={true}
            >
            </AutoComplete>
            <img src={Search} height={15}></img>
          </div>
          {loading ? <Skeleton active={true} /> : <div className={s.lineBox}>
            {searchStudentsData.length !== 0 ? searchStudentsData.map((student: any, index: number) => (
              <div className={searchStudentsData.length != 1 ? s.card : s.cardSingle} key={index} onClick={() => { navigate(`${appRoutes.TEACHER_STUDENT_HISTORY}/${student['uid']}`, { state: student }) }}>
                <img src={student['profile']} height={80}></img>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", fontSize: "1.3em", fontWeight: "bold", color: "var(--title-color)", textTransform: "uppercase", marginTop: "10px" }}>{student['firstname']} {student['lastname']}</div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", background: "#D5D5D5", paddingTop: "2.5px", paddingBottom: "2.5px", borderRadius: "8px", width: "100px", margin: "10px auto" }}>
                  {student['levelId']['title']}
                </div>
                <div className={s.rateBox}>
                  <div className={s.dashedBox}>
                    <span style={{ color: "#7FB1B2" }}>Cours</span>
                    <span className={s.rateBoxSpan}><img src={Star} height={10} className={s.rateBoxStar}></img>{student['rateCours']}</span>
                  </div>
                  <div className={s.dashedBox}>
                    <span style={{ color: "#7FB1B2" }}>Application</span>
                    <span className={s.rateBoxSpan}><img src={Star} height={10} className={s.rateBoxStar}></img>{student['rateApplication']}</span>
                  </div>
                  <div className={s.dashedBox}>
                    <span style={{ color: "#7FB1B2" }}>Exercice</span>
                    <span className={s.rateBoxSpan}><img src={Star} height={10} className={s.rateBoxStar}></img>{student['rateExercice']}</span>
                  </div>
                </div>
                <div style={{ margin: "30px auto" }}>
                  <button className={s.defaultButton} onClick={() => { navigate(`${appRoutes.TEACHER_STUDENT_HISTORY}/${student['uid']}`, { state: student }) }}>Historique de cours</button>
                </div>
              </div>
            )) : studentsData.length > 0 ? (
              studentsData.map((student: any, index: number) => (
                <div className={studentsData.length != 1 ? s.card : s.cardSingle} key={index} onClick={() => { navigate(`${appRoutes.TEACHER_STUDENT_HISTORY}/${student['uid']}`, { state: student }) }}>
                  <img src={student['profile']} height={80}></img>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", fontSize: "1.3em", fontWeight: "bold", color: "var(--title-color)", textTransform: "uppercase", marginTop: "10px" }}>{student['firstname']} {student['lastname']}</div>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", background: "#D5D5D5", paddingTop: "2.5px", paddingBottom: "2.5px", borderRadius: "8px", width: "100px", margin: "10px auto" }}>
                    {student['levelId']['title']}
                  </div>
                  <div className={s.rateBox}>
                    <div className={s.dashedBox}>
                      <span style={{ color: "#7FB1B2" }}>Cours</span>
                      <span className={s.rateBoxSpan}><img src={Star} height={10} className={s.rateBoxStar}></img>{student['rateCours']}</span>
                    </div>
                    <div className={s.dashedBox}>
                      <span style={{ color: "#7FB1B2" }}>Application</span>
                      <span className={s.rateBoxSpan}><img src={Star} height={10} className={s.rateBoxStar}></img>{student['rateApplication']}</span>
                    </div>
                    <div className={s.dashedBox}>
                      <span style={{ color: "#7FB1B2" }}>Exercice</span>
                      <span className={s.rateBoxSpan}><img src={Star} height={10} className={s.rateBoxStar}></img>{student['rateExercice']}</span>
                    </div>
                  </div>
                  <div style={{ margin: "30px auto" }}>
                    <button className={s.defaultButton} onClick={() => { navigate(`${appRoutes.TEACHER_STUDENT_HISTORY}/${student['uid']}`, { state: student }) }}>Historique de cours</button>
                  </div>
                </div>
              ))
            ) : <AppResult status={"info"} title="Aucun élève" />}
          </div>}
        </div>
      </AppScreen>
    </React.Fragment>
  );
};

export default TeacherStudents;
