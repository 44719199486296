import Card from "components/card";
import AppButton from "components/libs/button";
import { FC } from "react";

//!STYLES
import { Avatar, Tooltip } from "antd";
import Matiers from "components/matiers";
import s from "./assets/cardHistory.module.css";

//IMAGES
import moment from "moment";
import { ReactComponent as Camera } from "../../../../../assets/images/app/camera.svg";
import STUDENTS from "../../../../../assets/images/students/student.svg";

type Props = {
  item: any;
};
const CardHistory: FC<Props> = ({ item }) => {
  return (
    <Card className={s.card}>
      <div className={s.content}>
        <div className={s.round__user}>
          <Avatar src={item?.studId?.profile || STUDENTS} size={60} />
        </div>
        <Tooltip
          placement="top"
          title={`${item?.studId?.firstname} ${item?.studId?.lastname}`}
        >
          <h3
            style={{ textAlign: "center" }}
          >{`${item?.studId?.firstname} ${item?.studId?.lastname}`}</h3>
        </Tooltip>
        <Matiers cours={item?.topic?.title} level={item?.level?.title} />
        <div className={s.__start}>
          {/* <Image preview={false} src={START} /> */}
          <span>{item?.price}€</span>
        </div>
        <span>
          {moment(item?.startDate).format("LL")} à{" "}
          {moment.utc(item?.startDate).format("h:mm")}
        </span>
        <a href={item?.profLink} target="_blank">
          <AppButton
            icon={
              <Camera
                style={{
                  position: "relative",
                  width: "15px",
                  top: "3.5px",
                  left: "-5px",
                }}
              />
            }
            className={s.__button}
          >
            Lien videoconf
          </AppButton>
        </a>
      </div>
    </Card>
  );
};

export default CardHistory;
