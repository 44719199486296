import styled from "styled-components";

// !STYLE
const StyledAccordion = styled.div`
  width: 100%;
  .ant-collapse {
    border: none !important;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    align-items: flex-start;
    padding: 12px 16px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 1.5715;
    cursor: pointer;
    transition: all 0.3s, visibility 0s;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    /* border: 1px solid #d5d5d5; */
    border-radius: 10px !important;
    background-color: white;
    margin-bottom: 10px !important;
    border: 1px solid gray !important;
  }

  textarea.ant-input {
    border-radius: 10px !important;
    margin-bottom: 20px;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px !important;
  }
`;
// !CONSTS
export const VIREMENT_BANCAIRE = "Virement bancaire";
export const WESTERN_UNION = "Western union";
export default StyledAccordion;
