import { SearchOutlined } from "@ant-design/icons";
import {
  Divider,
  Row,
  Skeleton,
  Typography
} from "antd";
import {
  Fragment,
  useEffect
} from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

//!STYLES
import Col from "antd/es/grid/col";
import AppResult from "components/appResults";
import AppButton from "components/libs/button";
import H2 from "components/libs/subtitle";
import * as appRoutes from "config/routes.config";
import moment from "moment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetHistoriqueStudentQuery } from "store/services/teacher/classes.services";
import s from "../assets/calendar.module.css";
import CardReservations from "./card";

const { Text } = Typography;

const CalendarOfReservationStudent = () => {

  const { user } = useSelector((state) => state.auth);
  const { isReservationsUpdated } = useSelector((state) => state.app);
  const {
    data: reservations,
    isLoading,
    isError,
    refetch,
  } = useGetHistoriqueStudentQuery(user?._id);

  const navigate = useNavigate();
  const handleClick = () => {
    navigate(appRoutes.STUDENT_SEARCH_BEST_TEACHER);
  }

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    refetch();
  }, [isReservationsUpdated]);

  const reservationsData =
    reservations?.data.reduce((acc: any, curr: any) => {
      const index = acc.findIndex((x: any) => x.startDate === curr.startDate);

      if (index > -1) {
        acc[index] = {
          ...acc[index],
          data: [...acc[index].data, curr],
        };
        return acc;
      }

      return [
        ...acc,
        {
          startDate: curr.startDate,
          data: [curr],
        },
      ];
    }, []) || [];

  return (
    <div>
      {isLoading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <Fragment>
          {reservationsData?.length > 0 ? (
            <div className={s.calendar}>
              {reservationsData?.map((reservation: any, index: number) => (
                <div key={index}>
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "2em" }}
                  >
                    <Col>
                      <H2>{moment(reservation?.startDate).format("LL")}</H2>
                    </Col>
                  </Row>

                  {reservation?.data.map((cren: any, index: number) => (
                    <CardReservations
                      key={index}
                      cren={cren}
                      isHistorique={false}
                    />
                  ))}

                  <Divider />
                </div>
              ))}
            </div>
          ) : (
            <>
              <AppResult status={"info"} title={
                <>
                  Aucune réservation
                  <br />
                  Trouve ton prof et réserve ton premier cours
                </>
              } />
              <div style={{ marginBottom: "30px", display: "flex", alignItems: "center", justifyContent: "center", }}>
                <AppButton icon={<SearchOutlined />} onClick={handleClick}>
                  Trouver un prof
                </AppButton>
              </div>
            </>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default CalendarOfReservationStudent;
